import React from "react";
import { Footer, Navbar } from "../components";
import GoBackButton from "../components/common/GoBackButton";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const labSpecs = useSelector((state) => state.labSpecs);
  const labName = labSpecs.lab_name;
  const registeredAddress = labSpecs.business_entity.registered_address;
  const hardCopyCost = labSpecs.general_settings.cost_of_hard_copies;
  const labEmail = labSpecs.general_settings.contact_queries_email;
  const labPhone = labSpecs.general_settings.call_now_button_number;
  const aboutUs = labSpecs.business_entity.about_us;

  return (
    <>
      <Navbar />
      <main>
        <section className="max-w-7xl min-h-screen mx-auto mt-20 mb-24 relative space-y-4">
          <GoBackButton />
          <h1 className="font-bold text-2xl">About {labName}</h1>
          <p>{aboutUs}</p>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;
