import { Dialog } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { HTPAppContext } from "../../context/HTPContext";
import {
  checkUserExistance,
  createPatient,
} from "../../services/clientServices";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const AddPatient = () => {
  const { setStep, firebaseUID, token } = useContext(HTPAppContext);
  const [userData, setUserData] = useState(null);
  const genders = ["Male", "Female", "Others"];

  useEffect(() => {
    const findUserByFirebaseUid = async () => {
      try {
        const response = await checkUserExistance(firebaseUID);
        if (response.data.exists) {
          setUserData(response.data.user);
        } else {
          throw new Error("User dose not exists");
        }
      } catch (error) {
        console.log(error);
      }
    };

    findUserByFirebaseUid();
  }, [firebaseUID]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const formValues = getValues();
  const watchGender = watch("gender");

  const onSubmitForm = async (data) => {
    console.log(formValues);
    try {
      const response = await createPatient({
        name: data.name,
        age: data.age,
        gender: data.gender,
        user: userData.id,
      });
      toast.success("Patient Created");
      setStep(1);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Dialog.Panel className="w-[300px] md:w-[417px] transform overflow-hidden rounded-2xl bg-modelBgCart p-6 text-left align-middle shadow-xl transition-all">
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900"
      >
        <div className="flex items-center space-x-2">
          <div onClick={() => setStep(1)} className="mr-4 cursor-pointer">
            <img
              src={`https://${window.location.hostname}/static/assets/Back.png`}
              alt="back"
            />
          </div>
          <div className="text-xl font-medium">Patient Details</div>
        </div>
      </Dialog.Title>
      <div className="text-start py-2">
        <div className=" font-bold text-xl pt-2">Add new patient</div>
      </div>
      <form className="space-y-3" onSubmit={handleSubmit(onSubmitForm)}>
        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/assets/user.png`}
            alt=""
            className="absolute bottom-2"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="text"
                placeholder={
                  errors.name ? errors.name?.message?.toString() : "Name"
                }
                className={`${
                  errors.name
                    ? "border-red-700 placeholder:text-red-700"
                    : " border-black placeholder:text-black"
                } w-full ps-10 p-2 outline-none bg-transparent border-b`}
                {...register("name", {
                  required: "Name is required",
                })}
              />
            </div>
          </div>
          {/* <div className="text-red-700 ml-5 mt-6">
            {errors.name?.message?.toString()}
          </div> */}
        </div>

        <div className="relative">
          <img
            className="absolute  bottom-2"
            src={`https://${window.location.hostname}/static/svg/AgeIcon.png`}
            alt=""
          />
          <div className="flex space-x-4 items-center ">
            <div className="flex-1">
              <input
                type="Number"
                placeholder={
                  errors.name ? errors.age?.message?.toString() : "Age"
                }
                className={`${
                  errors.age
                    ? "border-red-700 placeholder:text-red-700"
                    : " border-black placeholder:text-black"
                } w-full ps-10 p-2 outline-none bg-transparent border-b`}
                {...register("age", {
                  required: "Age is required",
                })}
              />
            </div>
          </div>
          {/* <div className="text-red-700 ml-5 mt-6">
            {errors.age?.message?.toString()}
          </div> */}
        </div>

        <div className={` font-medium mb-2`}>Gender </div>

        {/* <GenderSelect /> */}
        <div className="flex space-x-2">
          {genders.map((item, index) => (
            <Controller
              key={index}
              control={control}
              name="gender"
              defaultValue=""
              rules={{ required: "Please select a gender" }}
              render={({ field }) => (
                <div
                  onClick={() => field.onChange(item)}
                  className={`p-2 border rounded-full w-[80px] cursor-pointer ${
                    field.value === item
                      ? "bg-primary text-white"
                      : "bg-white text-primary"
                  }`}
                >
                  <div className="text-center text-sm font-medium">{item}</div>
                </div>
              )}
            />
          ))}
        </div>
        {errors.gender && (
          <span className="text-red-700">* Gender is required</span>
        )}
        {/* <div className="text-red-700 ml-5 mt-6">
          {errors.gender?.message?.toString()}
        </div> */}
        {/* <div className="flex space-x-2">
          {genders.map((item, index) => {
            const isSelected = formValues.gender === item;
            return (
              <div
                key={index}
                onClick={() =>
                  setValue("gender", item, {
                    shouldValidate: true,
                    shouldDirty: true,
                    required: "Age is required",
                  })
                }
                className={`p-2 border rounded-full w-[80px] cursor-pointer ${
                  isSelected ? "bg-primary text-white" : "bg-white text-primary"
                }`}
              >
                <div className="text-center text-sm font-medium">{item}</div>
              </div>
            );
          })}
          <div className="text-red-700 ml-5 mt-6">
            {errors.gender && <p>Please select a gender.</p>}
          </div>
        </div> */}

        <input
          className="bg-primary text-white font-medium w-full py-[15px] mt-[36px] text-center text-lg rounded-[73px] transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer"
          type="submit"
          value="Submit"
        />
      </form>
    </Dialog.Panel>
  );
};

export default AddPatient;
