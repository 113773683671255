import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTests } from "../../redux/cart/cartSlice";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCart";

const CartPopOver = () => {
  const { isCartEmpty, totalTestsAndPackages } = useCart();
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  console.log(window.location.hostname);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="outline-none">
            <img
              src={`https://${window.location.hostname}/static/assets/Cart.png`}
              alt="Cart"
              className="cursor-pointer w-6 h-6 mt-2"
            />
            {!isCartEmpty && (
              <div className="w-2 h-2 mt-2 rounded-full bg-red-600 absolute top-0 right-0"></div>
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition duration-200 ease-out"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition duration-100 ease-in"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute min-w-[220px] z-10 px-4 lg:max-w-md bg-white -right-20 rounded-md">
              <div className="p-4  space-y-3">
                <div className="text-lg text-center font-bold">
                  Order Summary
                </div>
                {totalTestsAndPackages > 0 ? (
                  <div className="flex justify-between">
                    <span>
                      {totalTestsAndPackages} Test
                      {totalTestsAndPackages > 1 ? "s" : ""}
                    </span>
                    <span>₹ {cart.discounted}</span>
                  </div>
                ) : (
                  <div className="text-center">No Tests</div>
                )}
                <div>
                  <div
                    className="bg-primary px-8 py-3 md:py-2 text-md text-center text-white font-medium rounded-[73px] w-full md:w-full  transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer"
                    onClick={() => navigate("/cart")}
                  >
                    Checkout
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CartPopOver;
