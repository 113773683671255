import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <main className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center mx-3">
        <img
         src={`https://${window.location.hostname}/static/assets/404.png`}
          alt="404 Illustration"
          className="mx-auto w-48 h-48 mb-6"
        />
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-2">
          404 - Not Found
        </h1>
        <p className="text-gray-600 mb-4">
          Oops! The page you are looking for does not exist.
        </p>
        <Link to="/" className="text-blue-500 hover:underline">
          Go back to home
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
