import React, { useContext, useEffect, useState } from "react";
import { findOrderById } from "../../services/orderServices";
import { completedOrder } from "../../data/HTPData";
import {
  findSingleTestById,
  findTestPackageById,
} from "../../services/pakageServices";
import {
  convertToDateTimeString,
  addSlotTime,
} from "../../utils/timeSlotsUtils";
import { useParams } from "react-router-dom";
import {
  findPatientById,
  findAddressById,
} from "../../services/clientServices";
import { HTPAppContext } from "../../context/HTPContext";

const PurchaseCompleteMain = () => {
  const { token } = useContext(HTPAppContext);
  const [data, setData] = useState();
  const params = useParams();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const order = await findOrderById(params.id);
        const {
          patients,
          address,
          tests,
          test_packages,
          appointments,
          order_id,
          order_value,
        } = order.data;

        const patientsData = await Promise.all(
          patients?.map(async (patientId) => {
            const { data } = await findPatientById(patientId, token);
            return data;
          })
        );

        const addressResponse = await findAddressById(address, token);
        const addressData = addressResponse.data;

        const testPromises = tests?.map(async (testId) => {
          const { data } = await findSingleTestById(testId);
          return data;
        });
        const testsData = await Promise.all(testPromises || []);

        const testPackagePromises = test_packages?.map(
          async (testPackageId) => {
            const { data } = await findTestPackageById(testPackageId);
            return data;
          }
        );
        const testPackagesData = await Promise.all(testPackagePromises || []);

        // const appointment = convertToDateTimeString(appointments).slice(0, 22);
        const appointment = {
          slotStart: convertToDateTimeString(appointments).slice(0, 22),
          slotEnd: addSlotTime(appointments).slice(17, 22),
        };

        setData({
          order_value,
          order_id,
          patients: patientsData,
          address: addressData,
          tests: testsData,
          test_packages: testPackagesData,
          appointment,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrder();
  }, []);

  return (
    <section className="max-w-7xl mx-auto my-8 min-h-screen">
      <div className="text-3xl font-medium pl-6">Thank You!</div>
      <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-[16px] mt-8">
        <div className="mx-2 col-span-5">
          {/* <div className="bg-[#F2F2F2] p-5 rounded-[24px] space-y-2">
            <div className="text-xl font-medium text-green-600 pb-4">
              Your Order has been confirmed.
            </div>
            <div className="font-medium">Order ID : {data?.order_id}</div>

            {data?.order_reports.map((report, index) => (
              <div
                key={index}
                className="flex justify-between font-medium py-1 border-b border-gray-700"
              >
                <div>{report.patient.name}</div>
                <div>{report.test_name}</div>
                <div>
                  <div>Sept 25, 2023</div>
                  <div>07:00 AM - 08:00 AM</div>
                </div>
              </div>
            ))}
          </div> */}

          <div className="bg-[#F2F2F2] p-5 rounded-[24px] space-y-2 mb-6">
            <div className="text-xl font-medium text-green-600 pb-4">
              Your Order has been confirmed.
            </div>

            <div className="grid md:grid-cols-3 font-medium gap-2">
              <div className="font-bold">Order ID </div>
              <div className=" col-span-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                {data?.order_id}
              </div>

              <div className="font-bold">Patients </div>
              <div className=" col-span-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                {data?.patients.map((patient, index, array) => {
                  return `${patient.name}${
                    index != array.length - 1 ? "," : ""
                  } `;
                })}
              </div>

              {data?.tests.length > 0 && (
                <>
                  <div className="font-bold">Tests </div>
                  <div className=" col-span-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                    {data?.tests.map((test, index, array) => {
                      return `${test.name}${
                        index != array.length - 1 ? "," : ""
                      } `;
                    })}
                  </div>
                </>
              )}

              {data?.test_packages.length > 0 && (
                <>
                  <div className="font-bold">Test Packages</div>
                  <div className=" col-span-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                    {data?.test_packages.map((testPackage, index, array) => {
                      return `${testPackage.name}${
                        index != array.length - 1 ? "," : ""
                      } `;
                    })}
                  </div>
                </>
              )}

              <div className="font-bold">Appointment </div>
              <div className=" col-span-2">
                {data?.appointment.slotStart} - {data?.appointment.slotEnd}
              </div>

              <div className="font-bold">Address </div>
              <div className=" col-span-2">
                {data?.address.flat}, {data?.address.locality},{" "}
                {data?.address.city}, {data?.address.state}, {data?.address.pin}
              </div>

              <div className="font-bold">Phone </div>
              <div className=" col-span-2">{data?.address.mobile}</div>
            </div>
          </div>
        </div>

        <div className="mx-5 lg:col-span-3">
          <div className=" bg-gray-50 rounded-[24px] p-[20px]">
            <div className="py-2 border-b border-slate-800 mb-4">
              <div className=" font-medium text-xl">Order Summary</div>
            </div>
            <div className="bg-[#F2F2F2] p-[12px]  rounded-md space-y-3 font-medium">
              {/* <div className="flex justify-between items-center">
                <div>MRP Total</div>
                <div>₹ 100</div>
              </div>
              <div className="flex justify-between items-center">
                <div>Price Discount</div>
                <div>- ₹ 100</div>
              </div> */}

              <div className="flex justify-between items-center">
                <div>Amount Paid</div>
                <div className="font-semibold">₹ {data?.order_value}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PurchaseCompleteMain;
