import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  selectTests,
  selectPackages,
  removeFromCart,
  removePackageFromCart,
} from "../redux/cart/cartSlice";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { clearPatientState } from "../redux/patient/patientSlice";

const useCart = () => {
  const dispatch = useDispatch();
  const cartTests = useSelector(selectTests);
  const cartPackages = useSelector(selectPackages);
  const isCartEmpty = cartTests.length < 1 && cartPackages.length < 1;
  const totalTestsAndPackages = cartPackages.length + cartTests.length;

  useEffect(() => {
    dispatch(clearPatientState());
  }, [isCartEmpty]);

  const isAddedToCart = (productId, productType) => {
    if (productType === "single") {
      return cartTests.some((test) => test.element.id === productId);
    } else if (productType === "package") {
      return cartPackages.some(
        (testPackage) => testPackage.element.id === productId
      );
    }
  };

  const addProductToCart = (product, added) => {
    if (added) {
      toast.error("Already added to the cart");
      return;
    }
    dispatch(addToCart({ element: product }));
    toast.success("Package Added");
  };

  const removeTestFormCart = (testId) => {
    dispatch(removeFromCart({ id: testId }));
    toast("Package / Test removed");
  };

  const removeTestPackageFromCart = (packageId) => {
    dispatch(removePackageFromCart({ id: packageId }));
    toast("Package / Test removed");
  };

  return {
    isAddedToCart,
    addProductToCart,
    removeTestFormCart,
    removeTestPackageFromCart,
    isCartEmpty,
    totalTestsAndPackages,
  };
};

export default useCart;
