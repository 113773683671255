import Cookies from "js-cookie";
import { useContext } from "react";
import toast from "react-hot-toast";
import { HTPAppContext } from "../context/HTPContext";
import { firebaseSignout } from "../firebase/firebaseUtils";

const useUser = () => {
  const {
    setIsAuthDialogOpen,
    isAuthDialogOpen,
    setModalType,
    modalType,
    setUserDataContext,
    setLabName,
    setFirbaseUID,
    setIsRegisterModalOpen,
    setToken,
    setUserPhone,
  } = useContext(HTPAppContext);

  const setAccess = (firebaseConfirmation, userData) => {
    const currTime = new Date().getTime();
    const expiryTime =
      parseInt(firebaseConfirmation._tokenResponse.expiresIn) * 1000 + currTime;

    Cookies.set("idToken", firebaseConfirmation._tokenResponse.idToken);
    Cookies.set("tokenExpiryTime", expiryTime);
    Cookies.set("userId", userData.user.id);
    Cookies.set("firebaseUid", firebaseConfirmation._tokenResponse.localId);
    Cookies.set("labName", userData.user.lab_name);

    setUserDataContext(userData.user);
    setLabName(userData.user.lab_name);
    toast.success("Logged in Successfully!");
    setIsAuthDialogOpen(false);
  };

  const setUserDataAccess = (userData) => {
    Cookies.set("userId", userData.id);
    Cookies.set("labName", userData.lab_name);
    setUserDataContext(userData);
    setLabName(userData.lab_name);
  };

  const registerUser = (firebaseConfirmation) => {
    const currTime = new Date().getTime();
    const expiryTime =
      parseInt(firebaseConfirmation._tokenResponse.expiresIn) * 1000 + currTime;

    Cookies.set("idToken", firebaseConfirmation._tokenResponse.idToken);
    Cookies.set("tokenExpiryTime", expiryTime);
    Cookies.set("firebaseUid", firebaseConfirmation._tokenResponse.localId);
    toast("Register new user");
    setIsRegisterModalOpen(true);
  };

  const removeAccess = () => {
    firebaseSignout()
      .then((res) => {
        Cookies.remove("idToken");
        Cookies.remove("tokenExpiryTime");
        Cookies.remove("userId");
        Cookies.remove("firebaseUid");
        Cookies.remove("labName");

        setUserDataContext(null);
        setLabName(null);
        setToken(null);
        // toast("user logged out");
      })
      .catch((error) => {
        console.log(error);
        toast.success(error.message);
      });
  };

  return { setAccess, setUserDataAccess, registerUser, removeAccess };
};

export default useUser;
