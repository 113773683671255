import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  lab_name: "",
  lab_address: "",
  pincode: "",
  lab_logo: null,
  lab_registration: "",
  business_entity: {
    id: null,
    company_name: "",
    pan_number: "",
    gst_number: "",
    registered_address: "",
    about_us: "",
    email_for_legal_questions: "",
    phone_for_legal_questions: "",
    lab: null,
  },
  general_settings: {
    id: null,
    cost_of_hard_copies: "",
    call_now_button_number: "",
    contact_queries_email: "",
    facebook_link: "",
    instagram_link: "",
    linkedin_link: "",
    lab: null,
  },
  management_member: [],
  payment_information: {
    id: null,
    razor_key_id: "",
    razor_key_secret: "",
    lab: null,
  },
  siteSettings: {
    allowPayments: false,
    haveBusinessEmail: false,
    haveBusinessPhone: false,
    haveCustomerQueryPhone: false,
    haveHardCopyCost: false,
  },
  isAppReady: false,
};

const labSpecsSlice = createSlice({
  name: "lab_specs",
  initialState,
  reducers: {
    setLabSpecs: (state, action) => {
      const newState = {
        ...action.payload,
        siteSettings: configureSettings(action.payload),
        isAppReady: checkAppStatus(configureSettings(action.payload)),
      };
      return newState;
      // return { ...state, ...action.payload };
    },
  },
});

const configureSettings = (payload) => {
  const razorKeyId = payload.payment_information.razor_key_id;
  const razorKeySecret = payload.payment_information.razor_key_secret;

  const links = {
    facebook: payload.general_settings.facebook_link,
    instagram: payload.general_settings.instagram_link,
    linkdin: payload.general_settings.linkedin_link,
  };

  const businessDetails = {
    businessEmail: payload.business_entity.email_for_legal_questions,
    businessPhone: payload.business_entity.phone_for_legal_questions,
    customerQueryPhone: payload.general_settings.call_now_button_number,
    hardCopyCost: payload.general_settings.cost_of_hard_copies,
  };

  return {
    allowPayments: razorKeyId && razorKeySecret ? true : false,
    haveBusinessEmail: businessDetails.businessEmail ? true : false,
    haveBusinessPhone: businessDetails.businessPhone ? true : false,
    haveCustomerQueryPhone: businessDetails.customerQueryPhone ? true : false,
    haveHardCopyCost: businessDetails.hardCopyCost !== 0 ? true : false,
  };
};

const checkAppStatus = (payload) => {
  const allKeysTrue = Object.values(payload).every((value) => value === true);
  return allKeysTrue;
};

export const { setLabSpecs } = labSpecsSlice.actions;
export const labSpecsReducer = labSpecsSlice.reducer;
export default labSpecsSlice.reducer;
