import { useEffect, useState } from "react";
import useFindApi from "../../hooks/useFindApi";
import OrderCard from "./OrderCard";
import Loader from "../loader/Loader";
import GoBackButton from "../common/GoBackButton";
import { sampleOrders, newOrderList } from "../../data/HTPData";
import { findOrders } from "../../services/orderServices";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const OrdersList = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userId = Cookies.get("userId");
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await findOrders(userId);
        setData(response.data);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <section className="max-w-7xl mx-auto ">
      <GoBackButton />
      <div className="bg-white px-4 py-6 mb-8 rounded-[24px]">
        <div className="py-2 ps-4 border-b-2 border-slate-500 font-medium text-lg">
          My Orders
        </div>
        <div className="space-y-4 pt-8">
          {data?.map((order, index) => {
            return <OrderCard key={index} order={order} />;
          })}
        </div>
        <div className="max-w-7xl mx-auto mt-[50px] mb-[100px]">
          <div className="h-[250px] mt-2 p-4 flex justify-center items-center">
            <h1 className="font-medium text-2xl text-primary">
              No Orders Found
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrdersList;
