import { useDispatch, useSelector } from "react-redux";
import { addToCart, selectTests } from "../../redux/cart/cartSlice";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { dummyTests } from "../../data/HTPData";
import { useParams } from "react-router-dom";
import AtcButton from "./AtcButton";

const ATC = () => {
  const packageDetails = useSelector((state) => state.testPackage);

  return (
    <section className="max-w-7xl mx-auto py-8">
      <div className="flex justify-between items-center">
        <div className="font-medium text-2xl">{packageDetails.name}</div>
        <div>
          <AtcButton />
        </div>
      </div>
    </section>
  );
};

export default ATC;
