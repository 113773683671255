import React from "react";
import { Footer, Navbar } from "../components";
import GoBackButton from "../components/common/GoBackButton";
import { useSelector } from "react-redux";

const TermsAndConditions = () => {
  const labSpecs = useSelector((state) => state.labSpecs);
  const labName = labSpecs.lab_name;
  const registeredAddress = labSpecs.business_entity.registered_address;
  const hardCopyCost = labSpecs.general_settings.cost_of_hard_copies;
  const labEmail = labSpecs.general_settings.contact_queries_email;
  const labPhone = labSpecs.general_settings.call_now_button_number;

  return (
    <>
      <Navbar />
      <main className="">
        <section className="max-w-7xl mx-auto mt-20 mb-24 relative space-y-4 ">
          <GoBackButton />
          {/* <h1 className="font-medium text-3xl text-center">
            Terms And Conditions
          </h1> */}
          <h1 className="font-bold text-2xl">
            Welcome to{" "}
            {labName || <span className="text-gray-500">Legal Name</span>}{" "}
            (referred as the company){" "}
          </h1>
          <p>
            Please read these Terms & Conditions carefully before you access any
            information and utilize{" "}
            <span className="font-bold">The company’s</span> diagnostic
            services. By agreeing to our Terms of Use, you acknowledge that you
            accept these terms & conditions and will abide by them. If you do
            not agree with our terms of use, you should stop accessing the
            information or using the services. The content available on this
            website is related to the company and is the property of {labName} &
            along with its subsidiaries with its registered office at{" "}
            {registeredAddress || (
              <span className="text-gray-500">Legal Address</span>
            )}
            . {labName || <span className="text-gray-500">Legal Name</span>} is
            India’s leading omnichannel pan India service provider which
            provides quality and affordable diagnostic services through home
            sample collection or visit to the nearest lab or collection centre.
            It offers quality sample collection and testing services across its
            network of labs and a notable number of experienced phlebotomists.
          </p>{" "}
          <p>
            The website or the internet platform is referred to as the company
            along with its Subsidiaries and we refer to ourselves as “We”, “Us”
            Or “Our”. We control and manage the website on our own. We reserve
            all rights to make changes or amendments in our terms of use at any
            time without prior intimation by posting changes on the company
            website. The users who agree to these Terms & Conditions of the
            company are responsible for keeping a check on the information
            posted on the website and changes made in the terms of use.
          </p>{" "}
          <p>
            {" "}
            By using, browsing or in any way transacting on the website, or
            using any services, you indicate that you agree to be bound by these
            Terms & conditions mentioned herein. In addition, by impliedly or
            explicitly accepting our Terms & Conditions, you also agree to be
            bound by the company’ policies, including the Privacy Policy, and
            such other rules, regulations, policies, terms of use as are
            applicable under the appropriate law(s) in India and other countries
            for the purposes of using, browsing or transacting on our website,
            or utilizing any of the services, and such rules, regulations,
            policies, terms of use shall be considered to be integrated into,
            and perceived as part and parcel of these Terms & Conditions.
            However, if users wish to move away from the website to a third
            party website, they may be subject to other terms and conditions of
            use and privacy policy, as may be provided on such a website. In
            such an event, the terms of use and privacy policy pertinent to that
            website will administer their use of that website.
          </p>
          <h1 className="font-bold text-2xl">
            By using this website, you may agree and give consent to the
            following terms of use:
          </h1>
          <ul class="list-disc list-inside pl-8 space-y-2">
            <li>
              In case of non-acceptance with any pertinent conditions, rules or
              regulations, or the agreement by a website visitor/user,{" "}
              <span className="font-bold">the company</span> has the right to
              instantly cease the access or usage rights of the User to the
              services and to eliminate unaccepted information.
            </li>
            <li>
              the company may reveal or transfer perceptive personal data /
              information to the point where it is important to offer the
              services, to another entity or person, as mentioned in our Privacy
              Policy.
            </li>
            <li>
              the company reserves the right at any time to refuse or suspend
              access to any or all segments of services offered/Promotional
              activities/Programs/Membership through the website to any user,
              who we believe, has disagreed with any of the conditions of these
              Terms of Use/Agreement.
            </li>
            <li>
              The user acknowledges that any booking they place is subject to
              the terms and conditions outlined in these Terms of Use, which
              include, but are not limited to, service availability based on the
              serviceable zone
            </li>
          </ul>
          <h1 className="font-bold text-2xl">ELIGIBILITY</h1>
          <p>
            By using the Website and/or availing the Services, users represent
            and warrant that they have the right, authority, and capacity to
            agree with the Company policies in respect of the Services being
            offered and to abide by all of the conditions set forth herein.
          </p>
          <ol class="pl-8 mt-2 space-y-2 list-decimal list-inside">
            <li>
              You are responsible for all the transactions you make under your
              name or account.
            </li>
            <li>
              You are 18 years of age or over and have the legal rights to agree
              with the form of the binding contract in your jurisdiction. In
              case of a person under the age of 18 wanting to avail services
              offered by the Company, they can only do so through their
              Parents/Guardian’s active consent.
            </li>
            <li>
              You signify and warrant to the Company that you will use the
              services in a manner dependable with all the pertinent rules and
              regulations.
            </li>
          </ol>
          <h1 className="font-bold text-2xl">Services and Terms of Use</h1>
          <p>
            To access some of the contents or services available on the company
            website, users will need to create an account. For account creation,
            users are asked to fulfil the registration process by filling in the
            form the following details:
          </p>
          <ul class="list-disc list-inside pl-8 space-y-1">
            <li>Age</li>
            <li>Name</li>
            <li>Date Of Birth</li>
            <li>Gender</li>
            <li>Phone Number</li>
            <li>Email Id</li>
            <li>Location/Address</li>
          </ul>
          <p>
            By filling in your details, you agree that the company will rely on
            the data you have provided and thus you hereby guarantee that any
            and all information provided by you in the process of account
            creation is reliable and accurate. You further affirm that your
            consent to the company to process your data as per its Privacy
            Policy.the company offers services through the
            Website/app/Whatsapp/Call centre to assist customers/ users to get
            reliable diagnostic tests with exclusive health diagnostic packages.
          </p>
          <p>
            The comprehensive health packages include a combination of
            customized diagnostic tests that the Company offers and
            customers/users are informed from time to time about the health
            packages by the information mentioned on the company website.
          </p>
          <p>
            These diagnostic health packages also include home service (if
            available, at the nearest testing centre), wherein customers/users
            are provided with the home sample collection service. User may not
            be able to avail Services if their sample collection location is
            outside the company current scope of Service. the company will keep
            the user informed of the same at the time of confirming his/her
            booking.
          </p>
          <p>
            When a user/customer places a request to avail test or Health
            Packages, a unique Booking ID is generated in the name of the
            Customer. The Booking ID and other details related to diagnostic
            services are sent to the customer on their registered email-Id (If
            Provided), WhatsApp (if they opt-in) and via SMS on their registered
            mobile number.
          </p>
          <p>
            the company holds the right to make changes in the nature of
            services and offers mentioned in the Health packages. The changes
            will be notified to the users/ customers on the website. If user
            require any clarification with regard to information available in
            his/her booking, it is advised to contact the customer support team
            to get the resolution.
          </p>
          <p>
            Users may not utilize the services for motives of examining
            accessibility, performance or functionality of the company, or for
            any other standardized or competitive purposes. The user is hereby
            given a restricted, non-exclusive, non-transferable right to use our
            services exclusively for user’s personal non-commercial use and in
            agreement with the permitted Terms & Conditions provided herein.
            Without restriction to the preceding, in the event the user is
            expelled from undertaking lawfully binding requirements under the
            Indian Contract Act, 1872, or are for any reason, unable to offer
            ‘Consent’ as per the Information Technology (Reasonable safety
            practices and methods and sensitive personal data or information)
            Rules, 2011 and the Digital Personal Data Protection Act, 2023, the
            user is not entitled to register for, use or utilize the services
            available on the Website/ App or any other channel.
          </p>
          <p>
            the company will offer the necessary support for the services at no
            additional cost, and/or advanced support, if bought separately, and
            will use commercially practical efforts to make the services
            accessible. the company will offer the services only in compliance
            with pertinent applicable laws and government regulations.
          </p>
          <p>
            the company may, at its sole prudence, defer or restrict user’s
            ability to use or access the Website at any time without prior
            intimation. This may be done in the view of investigations for
            complaints or alleged violations of these Terms & Conditions, or for
            any other reason. the company reserves all the rights to control
            profiles of diagnostic centers/ labs/ collection centres to make
            them more appropriate for Package navigations on the Website. If
            users find any incorrect data on the Website, they can contact
            Redcliffe at care@the company instantly for such issues. the company
            shall not be held responsible for any event in this regard.
          </p>
          <p>
            Users shall be bound by these terms of use of this Contract. Any
            violation of the agreement Terms of Use gives the right to the the
            company to take required action against the user.
          </p>
          <p>
            Shipping and delivery of reports: In case of home sample collection,
            we provide e-reports on the registered mobile number via sms/
            WhatsApp and registered email. In case, a user/customer opts for a
            hard copy of the report, then they need to pay additional Rs{" "}
            {hardCopyCost || (
              <span className="text-gray-500">Hard copy cost</span>
            )}{" "}
            per report for the courier of reports to their address. We assure
            users/ customers that we take all necessary measures to ensure the
            report gets delivered on time.
          </p>
          <h1 className="font-bold text-2xl">
            Sample collection and Report Generation at the company
          </h1>
          <p>
            In addition to the T&Cs, coupon redemption is subject to standard
            and specified terms and conditions mentioned by the companys.
            Coupons are issued on behalf of respective retailers. Hence, any
            damages, injuries, losses incurred by the You as a result of using
            the coupon is not the responsibility of the company and its
            affiliates, and each of their directors, officers, employees. It is
            advised to read the detailed terms & condition of respective coupon
            in detail before using it on the company website. This term
            supersedes any offer related to any other the company Service.
          </p>
          {/*  */}
          <ol class="pl-8 mt-2 space-y-2 list-decimal list-inside">
            <li>
              Information on how to participate forms part of these Terms &
              Conditions. By participating, claimants/ user agrees to be bound
              by these Terms & Conditions. Claimants/ user must comply with
              these Terms & Conditions for a coupon to be valid.
            </li>
            <li>
              Each claimant/ user is entitled to one coupon per Booking ID.
              Coupons are not transferable and are not redeemable for cash and
              cannot be combined with any other coupons or any other offer or
              discounts or promotions offered by the company.
            </li>
            <li>
              Each coupon is identified by a code and has different
              rewards/discount value. The claimant/user can apply the coupon
              during booking whilst being bound by the conditions linked to the
              redemption of the coupon.
            </li>
            <li>
              To redeem the coupon code, the claimant/user enters the coupon
              code into the promotional box during booking on website/app/other
              relevant channel wherever coupon is applicable and the relevant
              discount will be automatically deducted from the final price of
              the booking value. If the claimant/user fails to enter the coupon
              code at the time of booking as specified, the booking will not be
              eligible for the discount. Discounts may not be claimed after
              confirmation of a claimant’s/ user’s purchase/booking on the
              website/app/WhatsApp/call centre/ collection centre/ camp.
            </li>
            <li>
              Each coupon is valid for a limited time only and expires on the
              date specified in the email/SMS/WhatsApp sent to the claimant/user
              by the company.
            </li>
            <li>
              Coupons are valid only for diagnostic services offered by the
              company.
            </li>
            <li>
              Coupons cannot be combined with any other offers or discounts.
            </li>
            <li>Coupons are void if altered or duplicated.</li>
            <li>
              Coupons cannot be replaced if emails/SMS/WhatsApp are deleted by
              the claimant.
            </li>
            <li>
              A coupon cannot be applied to bookings previously placed with the
              company.
            </li>
            <li>
              Validity, Discount Amount, Cashback Amount, Minimum Booking Value
              & Redemption of coupon code (Web/App/Collection Centre, Call
              Center & Phlebotomist App) are subject to the terms & Conditions
              of the Specified Coupon Codes.
            </li>
            <li>
              Coupon codes available on third-party websites/ apps/ digital
              platforms or other channels will be validated by the company
              whether legitimate or not. If the coupon is not valid or legit,
              the discount shall not be applied.
            </li>
            <li>
              All coupon codes available on third-party websites/ apps/ digital
              platforms or other channels will have the same terms and
              conditions (TnC) as those of the company.
            </li>
            <li>
              Some of the coupon codes can be specifically designed for
              particular user types i.e. new user, repeat/ existing user, and
              can be platform-specific, i.e., CRM, Website, WhatsApp, CC and App
              as well.
            </li>
            <li>
              Coupon codes used in conjunction with VIP membership or any other
              offer will have an upper limit, which includes the VIP membership
              discount benefit. Hence, a user will get only the upper limit
              discount as the maximum discount.
            </li>
            <li>
              Use of coupons constitutes acceptance of these terms and
              conditions.
            </li>
            <li>
              If a coupon is used and an entire booking (in accordance with the
              cancellation policy) is cancelled at a later stage by the
              claimant, the coupon will no longer be valid.
            </li>
            <li>
              The company shall not be liable for any loss, damage or injury
              suffered or sustained (even if caused by negligence) as a result
              of accepting and/or using the coupon, except for any liability
              which cannot be excluded by law.
            </li>
            <li>
              The company accepts no responsibility for late, lost or
              misdirected email or other communications. The company assumes no
              responsibility for any failure to receive a claim or for
              inaccurate information or for any loss, damage or injury as a
              result of technical or telecommunications problems, including
              security breaches. If such problems arise, then the company may
              modify, cancel, terminate or suspend the coupon.
            </li>
            <li>Coupons are subject to all applicable laws and regulations.</li>
            <li>
              These Terms & Conditions shall be subject to the exclusive
              jurisdiction of the courts at Delhi and are governed by the laws
              of India.
            </li>
            <li>
              The company reserves the right to discontinue a coupon code at any
              time.
            </li>
          </ol>
          <p>
            Please write to us at{" "}
            {labEmail || <span className="text-gray-500">Email</span>} if you
            have any questions or need further clarification. If you are unable
            to make a query in writing, please ring us at{" "}
            {labPhone || <span className="text-gray-500">Phone</span>}
          </p>
          <h1 className="font-bold text-2xl">Copyright/Trademarks</h1>
          <p>
            The trademarks, logos, tagline and service marks (collectively
            referred to as "Marks") displayed on the the company
            Website/app/collection centre/marketing collaterals are our
            property. You are prohibited from using any Marks for any purpose
            including, but not limited to, the use as metatags on other pages or
            sites on the internet without our written permission, or the written
            permission of such other party which may own the Marks. All
            information and content including any software programs available on
            or through the the company Website/APP (collectively referred to as
            "Content") is protected by copyright. You are prohibited from
            modifying, copying, distributing, transmitting, displaying,
            publishing, selling, licensing, creating derivative works or using
            any Content available on or through the Redcliffe Loyalty Program
            Website for commercial or public purposes.
          </p>
          <p>Privacy policy</p>
          <p>
            Any personal information about you which you supply us with, or we
            collect about you is governed by the company privacy policy, a copy
            of which is available for review on the the company Website,
            together with information about cookies and the use of cookies, and
            the terms of which shall be deemed to be incorporated into these
            Terms and Conditions.
          </p>
          <p>Law and jurisdiction</p>
          <p>
            These Terms and Conditions and the relationship between us and you
            are governed by laws in India. By completing and submitting an
            Enrolment Form to join the Redcliffe Referral Program, you are
            agreeing to submit to the exclusive jurisdiction of Courts at Pune.
          </p>
          <p>Content</p>
          <p>
            the company website may include third-party website links. the
            company should not be held responsible for images, videos or such
            contents posted on websites other than the company/. Users should
            assess the applicable conditions or refer to the company’s Privacy
            Policy before using it. the company does not hold the responsibility
            of and does not intend to endorse any content, review, video, media
            publications, services or materials on or posted on third party
            sites. Users should access content or materials posted on third
            party websites at their own risk.
          </p>
          <p>
            Content or material submitted or published on other platforms or web
            pages should respect intellectual property rights. the company
            should be notified if the content located on or linked by the site
            violates the copyright. the company holds rights to delete or
            disable all links containing such content or materials. In the case
            of a user who may breach or repeatedly infringes upon the copyrights
            or other content of the company or others, the company may, in its
            choice, dismiss or deny access to and use of the Website and/or
            Services.
          </p>
          <p>PC-PNDT Act</p>
          <p>
            the company strongly agrees that pre-natal gender screening and
            pre-natal gender selection are illegal in India. It strongly follows
            the terms and conditions as mentioned in the PC-PNDT Act. The Act
            strictly prohibits sex determination or disclosure of the gender of
            the fetus. the company supports the prohibition of any content,
            video, graphic representation and review associated with the
            prenatal determination of sex. The person who does not abide by the
            provisions of this Act is bookable with imprisonment and a fine. the
            company does not endorse or promote any publication of content (on
            website or media) or validate any technique or treatment pertaining
            to sex determination or gender selection. Patient reviews or
            testimonials are purely third-party content on third party websites
            and the company cannot manage or control such activities. Through
            its website or webpage, the company by no means associates itself
            with any prohibited content.
          </p>
          <h1 className="font-bold text-2xl">Money Back Policy</h1>
          <p>
            Customer satisfaction remains our primary objective. Thus, we leave
            no stone unturned when providing the expected services to our
            customers. The Company will refund the money within 72 hours of the
            complaint being raised if it gets validated as per the process
            mentioned below:
          </p>
          <p>
            If your doctor is unsatisfied with the correctness of tests or
            reports, you may raise a complaint by emailing us on
            {labEmail || <span className="text-gray-500">Email</span>} with
            booking ID and doctor details. Doctor details shall include name of
            the doctor (must be a registered medical practitioner), clinic/
            hospital name, specialization, Date of consultation/ visit, Doctor
            prescription mentioning report remarks, Doctor’s Contact Number. The
            prescription must have doctor’s registration number and it should be
            signed and stamped.
          </p>
          <ol class="pl-8 mt-2 space-y-2 list-decimal list-inside">
            <li>
              To ensure the accuracy of your results, we will conduct the
              processing on the fresh sample which will be taken either on the
              day of the complaint or the next day as per your convenience and
              working operational hours. We'll also run the sample in our lab
              and in two independent different NABL-certified labs to validate
              the findings.
            </li>
            <li>
              If our report indicates inaccuracies, we are committed to offering
              you a 100% refund. However, if our results align within a
              variation of 10% to 15% compared to the other reports—attributable
              to differences in machinery or methodology—regrettably, you won't
              qualify for a refund.
            </li>
            <li>
              All complaints must be made within a 3-day period from the test
              report date.
            </li>
            <li>
              This is applicable only for pathology tests and does not include
              radiology tests.
            </li>
            <li>
              Refunds will be made to the original payment method or via bank
              transfer to your personal bank account.
            </li>
            <li>
              Our quality team reserves the right to make the final decision.
            </li>
          </ol>
          <p>
            In no event shall Redcliffe, its officers, directors, employees,
            partners or vendors be liable to the customer, or any third party
            for any special, incidental, indirect, consequential, or punitive
            damages. Notwithstanding anything to the contrary, Redcliffe’s
            entire liability to you under these terms & conditions or otherwise
            shall be the refund of the money charged from you for testing, under
            which the unlikely liability arises.
          </p>
          <h1 className="font-bold text-2xl">Cancellation & Refund Policy</h1>
          <p>
            This policy is made to ensure proper transparency between the
            Customer & Company.
          </p>
          <p>
            Any Customer desirous of making a refund request need to follow the
            guideline as have been laid down in this document.
          </p>
          <h1 className="font-bold text-2xl">Cancellation by the Company</h1>
          <p>
            In case of Cancellation or Non-confirmation of booking by the
            company for any reason, including but not limited to
            non-availability of the technical or human resources, the Customer
            shall have the option to either re-schedule the test or to request
            for refund in which case, the complete refund shall be issued to the
            Customer as per the terms.
          </p>
          <h1 className="font-bold text-2xl">Cancellation By the Customer</h1>
          <p>
            In the event that a customer wishes to cancel any test(s) ordered
            from the Company, they may do so by calling on support number or by
            cancelling on website/ app before the phlebo is assigned.. To
            initiate a refund, the customer must provide written request/notice
            to the Company via email/whatsapp or verbal request over the support
            call. Refund request can be made by writing to the Company at email
            address {labEmail || <span className="text-gray-500">Email</span>}{" "}
            with the subject line “Refund Request: [Booking ID]”{" "}
          </p>
          <p>The Customer shall provide the following information:</p>
          <ol class="pl-8 mt-2 space-y-2 list-decimal list-inside">
            <li>Booking ID</li>
            <li>Name of the Customer</li>
            <li>Contact Number</li>
            <li>Bank Account Number and IFSC Code</li>
          </ol>
          <p>
            The Refund shall be initiated by the Company after reasonable
            validation of the information received by the Company After the
            company completes the validation process, an SMS will be sent to the
            customer containing a link to update their account details. The
            process of validation can take upto 72 business hours. The refund
            will be processed upon successful verification of the details
            updated via the provided link. If the user does not complete the
            necessary details, the company cannot be held responsible for any
            delays in processing the refunds.
          </p>
          <p>Refund </p>
          <p>
            The Company, on receiving a valid request, shall initiate the refund
            process and the Customer shall receive the amount within 5-7 days
            from the date of refund intimation (This would be valid for the
            refund getting processed offline). This is subject to any clearance
            where 3rd party payment aggregators are involved, in which case, the
            payment shall be refunded back within 15 days.
          </p>
          <p>Usage and Transfer of Personal Information</p>
          <p>
            The company website may include pages that provide users with the
            opportunity to provide us with Personal Information about themselves
            which shall be considered as per the Privacy Policy of the company.
            Further users may agree to the following Terms of Use: Collection
            and Delivery are reliant on several practical and value parameters.
            the company assumes no responsibility towards any time delay caused
            on account of the above factors essential for review, analysis
            reporting and third-party service delay outside of our control. the
            company does not offer any medical advice and services offered must
            not be considered as an alternative for professional medical advice,
            diagnosis or treatment. Take proper guidance or medical advice from
            the professionals prior to booking a test for any medical condition.
            Please correlate clinically. the company shall not be held liable in
            any manner for the genuineness of the personal information or
            sensitive personal data or information provided by the User to the
            company or any other person acting on behalf of the company. The
            User is to be held liable for maintaining the privacy of their
            account access information and credentials (username & password) and
            restricting any unlawful access and use of Services through the
            company website/App. The User shall be held responsible for all uses
            of the User’s credentials, whether or not approved by the User. The
            User shall instantly inform the company of any actual or suspected
            unlawful use of the User’s account or password. If a User provides
            any information that is false, inaccurate, outdated or incomplete
            (or proves to be false, inaccurate, outdated or incomplete), or the
            company has reasonable grounds to believe that such information is
            incomplete, the company holds the right to suspend or terminate such
            account at its sole discretion. Users do hereby permit the company
            and its associated members to collect the electronic copy of the
            report from our Diagnostic centre/ Labs and offer the same to the
            Users.
          </p>
          <p>Modification of Terms of Service</p>
          <p>
            The company reserves all the rights to modify and update these Terms
            of Use and the Privacy Policy at any time without prior notice. As
            such, users are requested hereby to periodically evaluate these
            Terms of Use, Privacy Policy, and any other guidelines that may be
            available on this Website, each of which are available through the
            Website homepage. Continual practice of our Services will be
            considered acceptance of any modifications. The User hereby agrees
            that, during the period of this Contract, we may alter or modify the
            Terms and Conditions, and amend the services offered under this
            Agreement. Any such modification or change will be requisite and
            useful instantly on release of the modified Agreement or change to
            the Service(s) on our website. Unless we explicitly inform
            otherwise, these terms integrate and replace any other terms related
            to the Services. the company reserves all the rights to suspend
            service, or deny admittance to anyone who violates our policies or
            the terms and conditions mentioned herein, without prior notice or
            warning.
          </p>
          <p>
            Force Majeure – the company is not responsible for failure to
            perform any of its liabilities if such failure is as a result of
            Acts of God (for example: fire, flood, earthquake, storm, hurricane
            or other natural calamity), conflict, hostilities (regardless of
            whether war is declared), civil war, upheaval, revolt, insurrection,
            military or seized power or confiscation, terrorist activities and
            nationalization.
          </p>
          <p>
            Grievance Redressal: The Company shall attempt to deal with
            grievances or complaints of the Users/customers to the extent
            possible. Towards this the Company is dedicated to outline a
            Grievance Redressal Cell, which can be reached on the phone number
            as provided in the Website or may mail to care@the company. You can
            also find escalation matrix on the website where complete details
            are mentioned for support and grievance redressal.
          </p>
          <p>Personal information</p>
          <p>
            Any personal information explicitly or implicitly provided by you or
            collected by the company is governed by the company privacy policy,
            a copy of which is available for review on the the company Website,
            together with information about cookies and the use of cookies, and
            the terms of which shall be deemed to be incorporated into these
            Terms and Conditions.
          </p>
          <p>No warranties</p>
          <p>
            All Rewards and other content on the Redcliffe Loyalty Program
            website are, to the fullest extent permitted by applicable law,
            provided to you on an “as is” basis without warranty of any kind
            from the company either express or implied including, but not
            limited to, the implied warranties of merchantability and fitness
            for a particular purpose, title, non-infringement, security or
            accuracy.
          </p>
          <p>
            the company does not warrant or represent that the content on the
            Redcliffe Loyalty Program Website or any Linked Site is complete or
            up-to-date. the company is under no obligation to update any content
            on the Redcliffe Loyalty Program Website or any Linked Sites. the
            company may change the content or make improvements or changes on
            the Redcliffe Loyalty Program Website at any time without notice.
          </p>
          <p>Limitation of liability</p>
          <p>
            The information, products, and descriptions of Rewards and other
            services published on the Redcliffe Loyalty Program Website or a
            Linked Site may include inaccuracies or typographical errors, and we
            specifically disclaim any liability for such inaccuracies and
            errors. Other than to the extent required under applicable laws,
            under no circumstance will we be liable for any loss or damage
            caused by your reliance on information obtained through the
            Redcliffe Loyalty Program Website or any Linked Site or your
            reliance on any Reward obtained.
          </p>
          <p>
            We reserve the right to seek all remedies available at law and in
            equity for any misuse of the Redcliffe Loyalty Program Website
            and/or violations of these Terms and Conditions, including the right
            to block access to the Redcliffe Loyalty Program from particular
            internet address. You are deemed to have consented to receive
            communication from us electronically and you agree that such
            electronic communication satisfies any legal requirements that such
            communications be in writing.
          </p>
          <p>Copyright/Trademarks</p>
          <p>
            The trademarks, logos, and service marks (collectively referred to
            as "Marks") displayed on the Redcliffe Loyalty Program Website
            belongs to the company. You are prohibited from using any Marks for
            any purpose including, but not limited to, the use as metatags on
            other pages or sites on the internet without the written permission
            of the company, or the written permission of such other party which
            may own the Marks. All information and content including any
            software programs available on or through the Redcliffe Loyalty
            Program Website (collectively referred to as "Content") is protected
            by copyright. You are prohibited from modifying, copying,
            distributing, transmitting, displaying, publishing, selling,
            licensing, creating derivative works, or using any Content available
            on or through the Redcliffe Loyalty Program Website for commercial
            or public purposes.
          </p>
          <p>Changes or termination</p>
          <ol className="pl-8 mt-2 space-y-2 list-decimal list-inside">
            <li>
              The company may change the rules applicable to the Redcliffe
              Loyalty Program (including all or part of these Terms and
              Conditions) in whole or in part, at any time without notice.
              However, the company will make reasonable efforts to give you
              prior notice of the changes and any such changes to the rules
              applicable to the Redcliffe Loyalty Program shall apply with
              effect from the first date of their publication.
            </li>
            <li>
              The company has the right to terminate the Redcliffe Loyalty
              Program at any time.
            </li>
            <li>
              The company reserves the right to interpret and apply the policies
              and procedures communicated in these Terms and Conditions. All
              decisions made by the company shall be final and conclusive in
              each case. These Terms and Conditions supersede all previously
              published terms and conditions in respect of the Redcliffe Loyalty
              Program.
            </li>
            <li>
              The company shall not be liable for any loss or damage, whether
              direct or indirect, resulting from the termination or change of
              the Redcliffe Loyalty Program or any of the services, benefits,
              facilities, or arrangements which are available to Members
              according to the Redcliffe Loyalty Program.
            </li>
          </ol>
          <p>Law and jurisdiction</p>
          <p>
            These Terms and Conditions and the relationship between the company
            and you are governed by laws in India. By completing and submitting
            an Enrolment Form to join the Redcliffe Loyalty Program, you are
            agreeing to submit to the exclusive jurisdiction of Courts at Delhi.
          </p>
          <h1 className="font-bold text-2xl">1. Scope of Services:</h1>
          <p>
            The Company is providing the aforesaid offering through its online
            portal the company, app and through its designated contact numbers
            as aforesaid available on this page.
          </p>
          <p>
            The customer/ user has the option of booking for the services
            provided by the Company by clicking on the call and talking to our
            customer support executives or health advisors or they could fill up
            the form provided on the aforesaid website hyperlink of the Company.
            The Customer/ user would be redirected to a window where the
            Customer has to furnish the details such as name, age and/or city.
          </p>
          <p>
            Upon furnishing the details, the Customer/user will receive a call
            from the assignee of the Company in a time duration not limited to
            1minute as informed. The aforesaid call is subject to the duration
            required on receiving the Customer/user details by the assignee of
            Company through the online portal. Sometimes, due to the huge volume
            of calls to the call centre or technical glitch or network issue or
            server down issue or any other unforeseen glitch or issues, the time
            taken to connect with the customer might exceed by 1 minute.
          </p>
          <p>
            The assignee representative of the Company shall then record the
            details of the services which is required by the Customer/ User and
            shall book an appointment on behalf of the Customer/ user for the
            aforesaid services.
          </p>
          <p>
            Book test in 1-minute campaign highlights the accessibility of the
            platform in 1 minute where users/customers can start booking their
            tests or packages.
          </p>
          <h1 className="font-bold text-2xl">2. No Liability of Company:</h1>
          <p>
            Without prejudice to the information provided above, the Company and
            the Platform shall not be liable in any manner for and towards:
          </p>
          <ul class="list-disc list-inside pl-8 space-y-1">
            <li>
              Any delay/ failure to record the details or confirm booking due to
              cellular traffic and loss of cellular connection.
            </li>
            <li>
              Any unauthorized access to, use, or alteration of Your
              transmissions or data, pursuant to Your engagement with the
              Company, or
            </li>
            <li>
              Any type of inconvenience suffered by the User due to a failure on
              part of data collected through the aforementioned hyperlink.
            </li>
            <li>
              Any type of inconvenience suffered by the User due to late
              show-up, rescheduling, or cancellation of confirmed appointments,
              differences in the fee charged, inappropriate treatment and other
              allied difficulties, or
            </li>
            <li>
              Any deficiency, inadequacy, unsuitability or negligence in the
              delivery and performance of the healthcare services by the Company
            </li>
            <li>Any deficiency in services offered on the online portal; or</li>
            <li>
              Any other incident that occurred in connection with the choice
              made by the User, based on the information available through our
              Services.
            </li>
            <li>
              Any incorrect information that is supplied or provided to the
              Company by the User.
            </li>
          </ul>
          <h1 className="font-bold text-2xl">3. Personal Data:</h1>
          <p>
            The Company may collect some personal information, including
            sensitive personal data or information from the User for the
            purposes of providing facilitation services including scheduling
            appointments. This may include records of any such communications
            exchanged between the User and the assignee of the Company along
            with the data of the User/ Customer recorded on the online portal
            which shall be collected and stored in the Company or its
            affiliate’s servers, subject to the express consent of the User. The
            Company undertakes that all the information that the Company
            collect, receive, possess, store, use, deal, handle, transfer,
            retain and otherwise process is as per the Privacy Policy of the
            Company and is in compliance with the applicable data privacy laws
          </p>
        </section>
        <div className="htp_landing_blue_one overflow-hidden absolute right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
