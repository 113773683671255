import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cart/cartSlice";
import patientSlice from "./patient/patientSlice";
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from "./persistConfig";
import rootReducer from "./reducer/rootReducer";

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer
});

const persistor = persistStore(store);

export { store, persistor };