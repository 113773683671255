import { Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { HTPAppContext } from "../../context/HTPContext";

const MobileNav = () => {
  const { isNavOpen, setIsNavOpen, handleModal } = useContext(HTPAppContext);

  useEffect(() => {
    isNavOpen
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isNavOpen]);

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <Transition
      show={isNavOpen}
      as={Fragment}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed md:hidden top-0 left-0 w-screen h-screen bg-white z-999">
        <div className="flex justify-end p-4">
          <button
            className="text-white focus:ring-2 focus:ring-offset-2 focus:ring-black bg-gray-50 rounded-md"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <img
             src={`https://${window.location.hostname}/static/assets/Close.png`}
              alt=""
              className="w-8 h-8"
            />
          </button>
        </div>
        <div className="mt-16 flex flex-col space-y-10 text-2xl items-center">
          <Link to="/" onClick={closeNav}>
            Home
          </Link>
          <Link to="/cart" onClick={closeNav}>
            Cart
          </Link>
          <Link to="/contact" onClick={closeNav}>
            Contact us
          </Link>
          <Link to="/" onClick={closeNav}>
            Explore Categories
          </Link>
          <Link
            to="/"
            className="cursor-pointer hover:text-black/90"
            onClick={closeNav}
          >
            View Reports
          </Link>
          <div
            onClick={() => {
              handleModal("login");
              closeNav();
            }}
            className="font-semibold"
          >
            Login
          </div>
          <div
            onClick={() => {
              handleModal("register");
              closeNav();
            }}
            className="font-semibold"
          >
            Register
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default MobileNav;
