import { Link } from "react-router-dom";

const generateSlug = (category) => {
  return category.toLowerCase().replace(/\s+/g, "-");
};

const CategoryCard = ({ category }) => {
  const slug = generateSlug(category);

  return (
    <div className="bg-white/20 backdrop-blur-[18px] py-[32px] px-3 rounded-[24px] htp_category_card">
      <div className="text-center font-bold text-xl">{category}</div>
      <div className="flex justify-center">
        <div className="flex space-x-2 mt-[42px] items-center cursor-pointer self-center duration-200 hover:scale-105">
          <Link to={`/packages/${slug}`} className="text-primary font-medium">
            View All Packages
          </Link>
          <div>
            <img
             src={`https://${window.location.hostname}/static/assets/Forward.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
