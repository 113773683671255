import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD9pO3stObLZnuc3e1hAEiV72e3uGK-MnI",
  authDomain: "mylab-htp.firebaseapp.com",
  projectId: "mylab-htp",
  storageBucket: "mylab-htp.appspot.com",
  messagingSenderId: "68001782757",
  appId: "1:68001782757:web:f5ed1da62addabd060ed0c",

  // apiKey: "AIzaSyDn35BpUwJ4VVpMmGfrDq5lbfEW3k6YcBc",
  // authDomain: "htp-backend.firebaseapp.com",
  // projectId: "htp-backend",
  // storageBucket: "htp-backend.appspot.com",
  // messagingSenderId: "512585998398",
  // appId: "1:512585998398:web:a08d6d4df8be63ef9fb16f",

  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
