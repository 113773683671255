import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        className="my-2 p-4 bg-white/50 backdrop-blur-[18px] rounded-full"
        onClick={() => navigate(-1)}
      >
        <img
          width="25px"
          // src={`/icons/back.png`}
          src={`https://${window.location.hostname}/static/icons/back.png`}
        />
      </button>
    </div>
  );
};

export default GoBackButton;
