import { Link } from "react-router-dom";
// import { chips } from "../../data/HTPData";
import { useContext, useState } from "react";
import { HTPAppContext } from "../../context/HTPContext";
import useFetch from "../../hooks/useFetch";
import ChipsSkeleton from "../skeleton/ChipsSkeleton";

const Chips = ({ setChip, selectedChip, chips }) => {
  // const { setChip, selectedChip } = useContext(HTPAppContext);
  const [data, setData] = useState(chips);

  const handleChipClick = (chip) => {
    setChip(chip);
  };

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between my-4">
      <div className="flex flex-wrap space-y-2 space-x-3 md:space-y-0 md:space-x-2 justify-center">
        {/* set loading variable */}
        {false ? (
          <ChipsSkeleton />
        ) : (
          data.map((element, index) => {
            const isSelected = selectedChip === element;
            return (
              <div
                key={index}
                onClick={() => handleChipClick(element)}
                className={`rounded-[24px] px-3 py-2 border cursor-pointer ${
                  isSelected
                    ? "bg-white text-primary font-bold"
                    : "border-slate-400"
                } md:px-4 md:py-1 md:mx-2 md:text-lg`}
              >
                {element}
              </div>
            );
          })
        )}
      </div>
      <Link
        to="/categories"
        className="flex space-x-2 items-center cursor-pointer mt-4 lg:mt-0"
      >
        <div className="text-secondary font-medium">See all</div>
        <div>
          <img
            src={`https://${window.location.hostname}/static/assets/Arrow.png`}
            alt=""
          />
        </div>
      </Link>
    </div>
  );
};

export default Chips;
