import { Disclosure, Transition } from "@headlessui/react";
import { useSelector } from "react-redux/es/hooks/useSelector";

const Overview = () => {
  const packageDetails = useSelector((state) => state.testPackage);

  return (
    <section className="pt-4 max-w-7xl mx-auto">
      {/* <div className="font-bold text-xl mb-4">Overview</div> */}
      <div className="mb-8">
        <div className="font-bold text-xl mb-4">
          How {packageDetails.name} helps you?
        </div>
        <div className="font-medium">
          {/* {packageDetails.about_test?.split("\r\n").map((line, index) => (
            <p key={index} className="mb-4">
              {line}
            </p>
          ))} */}
          <div className="whitespace-pre-wrap">{packageDetails.about_test}</div>
        </div>
      </div>
      <div className="mb-4">
        <div className="font-bold text-xl mb-2">Precautions</div>
        <div className="py-2 inline-block font-medium rounded-xl">
          {/* {packageDetails.sample_collection_instructions
            ?.split("\r\n")
            .map((line, index) => (
              <p className="mb-4" key={index}>
                {line}
              </p>
            ))} */}
          <div className="whitespace-pre-wrap">
            {packageDetails.sample_collection_instructions}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;
