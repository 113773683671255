import React, { ReactNode, createContext, useEffect, useState } from "react";
import { getCity } from "../utils/HTPUtils";
import Cookies from "js-cookie";
import { firebaseSignout } from "../firebase/firebaseUtils";
import toast from "react-hot-toast";
import { checkUserExistance } from "../services/clientServices";

export const HTPAppContext = createContext(null);

const HTPContext = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [authUser, setAuthUser] = useState({
    accessToken: "",
    expireTime: new Date(),
    phoneNumber: "",
    refreshToken: "",
  });
  const [selectedChip, setSelectedChip] = useState("Popular Packages");
  const [selectedTestChip, setSelectedTestChip] = useState("Popular Tests");
  const [searchingCity, setSearchingCity] = useState(false);
  const [isCartStepOpen, setIsCartStepOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [patientDetails, setPatientDetails] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(getTokenFormCookies());
  const [firebaseUID, setFirbaseUID] = useState(getFirebaseUidFromCookies());
  const [labName, setLabName] = useState(Cookies.get("labName"));
  // User Data dose not work
  const [userDataContext, setUserDataContext] = useState(null);
  const [userPhone, setUserPhone] = useState(null);

  function getTokenFormCookies() {
    return Cookies.get("idToken");
  }

  function getFirebaseUidFromCookies() {
    return Cookies.get("firebaseUid");
  }

  async function findUserData(uid) {
    try {
      const response = await checkUserExistance(uid);
      return response;
    } catch (error) {
      toast.error("not a user");
      console.log(error);
    }
  }

  function useToken() {
    // setToken(getTokenFormCookies());
    // return token;
  }

  const removeToken = () => {
    Cookies.remove("idToken");
    Cookies.remove("firebaseUid");
    Cookies.remove("labName");
    setToken(null);
  };

  const logout = () => {
    firebaseSignout()
      .then((res) => {
        removeToken();
        // toast.success("user logged out")
      })
      .catch((error) => {
        console.log(error);
        toast.success(error.message);
      });
  };

  const detectLocation = (e) => {
    setSearchingCity(true);
    const targetElement = e.currentTarget;

    const parentElement = targetElement.parentElement;
    if (parentElement) {
      window.navigator.geolocation.getCurrentPosition(async (location) => {
        const inputElement = parentElement.children[0];
        const response = await getCity(
          location.coords.latitude,
          location.coords.longitude
        );
        inputElement.value = response.address.city;
        console.log(response);
        setSearchingCity(false);
      });
    }
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const handleModal = (type) => {
    setIsNavOpen(false);
    setIsAuthDialogOpen(true);
    setModalType(type);
  };

  const handleRegisterModal = () => {
    setIsNavOpen(false);
    setIsRegisterModalOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const fetchUserData = async () => {
      if (firebaseUID) {
        const response = await findUserData(firebaseUID);
        setUserDataContext(response.data.user);
      }
      setIsLoggedIn(Cookies.get("isLoggedin"));
      setToken(Cookies.get("idToken"));
    };
    fetchUserData();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HTPAppContext.Provider
      value={{
        isAppLoading,
        setIsAppLoading,
        isScrolled,
        isAuthDialogOpen,
        setIsAuthDialogOpen,
        isRegisterModalOpen,
        setIsRegisterModalOpen,
        isNavOpen,
        setIsNavOpen,
        modalType,
        setModalType,
        handleModal,
        handleRegisterModal,
        authUser,
        setAuthUser,
        selectedChip,
        selectedTestChip,
        isCartStepOpen,
        setIsCartStepOpen,
        step,
        setStep,
        setSelectedChip,
        setSelectedTestChip,
        setPatientDetails,
        patientDetails,
        logout,
        labName,
        labName,
        setLabName,
        useToken,
        token,
        setToken,
        userDataContext,
        setUserDataContext,
        firebaseUID,
        setFirbaseUID,
        detectLocation,
        userPhone,
        setUserPhone,
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </HTPAppContext.Provider>
  );
};

export default HTPContext;
