import { HTPApi } from "../api/ApiConfig";
const baseEndpoint = "/api/promote";

export const findQuickbookTests = async () => {
  const response = await HTPApi.get(`${baseEndpoint}/quickbooktests/`);
  return response;
};

export const findPromotions = async () => {
  const response = await HTPApi.get(`${baseEndpoint}/promotions/`);
  return response;
};
