import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect } from "react";
import { HTPAppContext } from "../../context/HTPContext";
import { useForm, Controller } from "react-hook-form";
import { createUser } from "../../services/clientServices";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import useUser from "../../hooks/useUser";
import { useSelector } from "react-redux";

const RegisterModal = () => {
  const {
    setIsRegisterModalOpen,
    isRegisterModalOpen,
    firebaseUID,
    userData,
    logout,
    token,
    userPhone,
    setIsLoggedIn,
  } = useContext(HTPAppContext);

  const labSpecs = useSelector((state) => state.labSpecs);
  const labName = labSpecs.lab_name;
  const { removeAccess, setUserDataAccess } = useUser();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    setError,
  } = useForm();

  useEffect(() => {
    setValue("phone", userPhone);
  }, [userPhone]);

  const onSubmitForm = async (data) => {
    if (!data.gender) {
      setError("gender", {
        type: "manual",
        message: "Please select a gender",
      });
      return;
    }

    try {
      const body = {
        name: data.name,
        address: data.address,
        gender: data.gender,
        phone: data.phone,
        pincode: data.pin,
        firebase_uid: firebaseUID,
        lab_name: labName,
      };

      const response = await createUser(body);
      console.log(response.data);
      setIsLoggedIn(true);
      toast.success("User Created");
      setUserDataAccess(response.data);
      closeModal();
    } catch (error) {
      removeAccess();
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleClose = () => {
    closeModal();
    removeAccess();
  };
  const closeModal = () => {
    setIsRegisterModalOpen(false);
    reset();
  };

  const SelectGenderRadios = () => {
    return (
      <>
        <div className="mt-2 space-y-2">
          <label className="font-medium">Select Gender</label>
          <div className="flex items-center gap-x-3">
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <input
                    id="gender-male"
                    type="radio"
                    {...field}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value="Male"
                  />
                  <label
                    htmlFor="gender-male"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Male
                  </label>
                </>
              )}
            />
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <input
                    id="gender-female"
                    type="radio"
                    {...field}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value="Female"
                  />
                  <label
                    htmlFor="gender-female"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Female
                  </label>
                </>
              )}
            />
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <input
                    id="gender-other"
                    type="radio"
                    {...field}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value="Other"
                  />
                  <label
                    htmlFor="gender-other"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Other
                  </label>
                </>
              )}
            />
          </div>
          <div className="text-red-700 ml-2 mt-2 font-medium">
            {errors.gender?.message?.toString()}
          </div>
        </div>
      </>
    );
  };

  //
  // MAIN COMPONNT
  //
  return (
    <Transition show={isRegisterModalOpen}>
      <Dialog
        open={isRegisterModalOpen}
        onClose={() => handleClose()}
        className="relative z-50 select-none"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black/25 backdrop-blur-md" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title>
                  <div className="text-start mb-4">
                    <div className=" font-bold text-2xl pt-2">
                      Register User
                    </div>
                  </div>
                </Dialog.Title>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="">
                    <div className=" rounded-2xl">
                      <div className="flex space-x-4 items-center">
                        <div className="flex-1">
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                          />
                        </div>
                      </div>
                      <div className="text-red-700 ml-2 mt-2 font-medium">
                        {errors.name?.message?.toString()}
                      </div>
                    </div>

                    <div className=" rounded-2xl">
                      <div className="flex space-x-4 items-center">
                        <div className="flex-1">
                          {/* <label>Address</label>
                          <textarea
                            className="w-full border p-2 border-slate-800 rounded-md"
                            rows={3}
                          ></textarea> */}
                          <input
                            type="text"
                            placeholder="Address"
                            {...register("address", {
                              required: "Address is required",
                            })}
                            className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                          />
                        </div>
                      </div>
                      <div className="text-red-700 ml-2 mt-2 font-medium">
                        {errors.address?.message?.toString()}
                      </div>
                    </div>

                    <div className=" rounded-2xl">
                      <div className="flex space-x-4 items-center">
                        <div className="flex-1">
                          <input
                            disabled={userPhone ? true : false}
                            type="text"
                            placeholder="Phone"
                            {...register("phone", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Must be of 10 digits length",
                              },
                            })}
                            className={`w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black ${
                              userPhone && "opacity-70"
                            }`}
                          />
                        </div>
                      </div>
                      <div className="text-red-700 ml-2 mt-2 font-medium">
                        {errors.phone?.message?.toString()}
                      </div>
                    </div>

                    <div className=" rounded-2xl">
                      <div className="flex space-x-4 items-center">
                        <div className="flex-1">
                          <input
                            type="email"
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                            })}
                            className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                          />
                        </div>
                      </div>
                      <div className="text-red-700 ml-2 mt-2 font-medium">
                        {errors.email?.message?.toString()}
                      </div>
                    </div>

                    <div className=" rounded-2xl">
                      <div className="flex space-x-4 items-center">
                        <div className="flex-1">
                          <input
                            type="text"
                            placeholder="Pin Code"
                            {...register("pin", {
                              required: "Pin Code is required",
                            })}
                            className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                          />
                        </div>
                      </div>
                      <div className="text-red-700 ml-2 mt-2 font-medium">
                        {errors.pin?.message?.toString()}
                      </div>
                    </div>
                  </div>

                  {/*  Gender Select */}

                  <div className=" rounded-2xl">
                    <div className="flex space-x-4 items-center">
                      <div className="flex-1">
                        {/* <label>Select Gender</label> */}
                        <SelectGenderRadios />
                      </div>
                    </div>
                  </div>

                  <input
                    className="bg-primary text-white font-medium w-full py-[15px] mt-[36px] text-center text-lg rounded-[73px] transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer"
                    type="submit"
                    value="Register"
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegisterModal;
