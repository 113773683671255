import { Fragment, useState, useEffect, useContext } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { searchTestPackages } from "../../services/pakageServices";
import { useNavigate } from "react-router-dom";
import SearchBarAtcButton from "./SearchBarAtcButton";

export function SearchCityCombobox({ placeholder, icon }) {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  // const location = useClientLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await searchTestPackages(query);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (query !== "") {
      fetchData();
    }
  }, [query]);

  const filteredItems =
    query === ""
      ? []
      : data?.filter((item) =>
          item.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleOptionSelect = (item) => {
    if (item.tests) {
      navigate(`/package/${item.id}?type=package`);
    } else {
      navigate(`/package/${item.id}?type=single`);
    }
  };

  const handleSearchedQuery = () => {
    if (query === "") {
      return;
    }
    navigate(`/packages/${query}`);
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    if (key === "Enter") {
      handleSearchedQuery();
    }
  };

  return (
    <div className="w-full ">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-3xl bg-white text-left focus:outline-none sm:text-sm ">
            <Combobox.Input
              onKeyDown={handleKeyPress}
              className="placeholder:text-base px-5 py-2 outline-none w-full rounded-3xl border"
              displayValue={(item) => ""}
              placeholder={placeholder}
              onChange={(event) => setQuery(event.target.value)}
            />
            <div
              onClick={() => handleSearchedQuery()}
              className="absolute right-3 top-2 cursor-pointer"
            >
              {icon}
            </div>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-50 text-base sm:text-sm ">
              {/* {loading && <div>Loading...</div>} */}
              {filteredItems?.length === 0 && query !== "" && !loading ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredItems?.map((item, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-4 pl-3 pr-4 text-base ${
                        active ? "bg-primary text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className="grid grid-cols-5 gap-4">
                        <div
                          className="col-span-4"
                          onClick={() => handleOptionSelect(item)}
                        >
                          <span
                            className={`block truncate ${
                              selected ? "font-bold" : "font-medium "
                            }`}
                          >
                            {item.name}
                          </span>
                          <span className={`block truncate font-medium`}>
                            Includes{" "}
                            {item?.tests?.length
                              ? `${item?.tests?.length} Tests`
                              : `1 Test`}
                          </span>
                        </div>
                        <div className="">
                          <SearchBarAtcButton active={active} item={item} />
                        </div>
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
