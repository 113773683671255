import {
  signInWithPhoneNumber,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signOut,
} from "firebase/auth";
import { auth } from "./firebaseInit";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
  size: "invisible",
});

auth.onAuthStateChanged((user) => {
  if (user) {
    Cookies.set("isLoggedin", true);
  } else {
    Cookies.set("isLoggedin", false);
  }
});

export const getConfirmationResult = async function (phoneNumber) {
  try {
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    return confirmationResult;
  } catch (error) {
    toast.error(error.message);
  }
};

export const authenticate = async function (confirmationResult, otp) {
  try {
    const credential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      otp
    );
    return await signInWithCredential(auth, credential);
  } catch (error) {
    toast.error(error.message);
  }
};

// function is being called in HTPContext first in the logout function
export const firebaseSignout = async () => {
  const response = await signOut(auth);
  return response;
};
