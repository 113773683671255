import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InProgress = () => {
  const [taskList, setTaskList] = useState(null);
  const siteSettings = useSelector((state) => state.labSpecs.siteSettings);
  const isAppReady = useSelector((state) => state.labSpecs.isAppReady);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAppReady) {
      navigate("/");
    }
  }, [isAppReady]);

  useEffect(() => {
    console.log(siteSettings);

    setTaskList([
      {
        task: "Provide Razorpay payment information",
        completed: siteSettings.allowPayments,
      },
      {
        task: "Provide business email",
        completed: siteSettings.haveBusinessEmail,
      },
      {
        task: "Provide business phone number",
        completed: siteSettings.haveBusinessPhone,
      },
      {
        task: "Provide cusotmer support phone number",
        completed: siteSettings.haveCustomerQueryPhone,
      },
      { task: "Set Hard Copy cost", completed: siteSettings.haveHardCopyCost },
    ]);
  }, []);

  return (
    <main className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="text-center mx-3 py-8">
        <img
          // src={`/assets/warning.png`}
          src={`https://${window.location.hostname}/static/assets/warning.png`}
          alt="Warning Illustration"
          className="mx-auto w-40 h-40 mb-6"
        />
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-2">
          Site Creation in progress
        </h1>
        <p className="text-gray-600 mb-8">
          Complete the following tasks to make website live.
        </p>
        <div>
          <ul className="max-w-md space-y-1 text-gray-700 list-inside">
            {taskList?.map((item, index) => (
              <li key={index} className="flex items-center">
                {item.completed ? (
                  <svg
                    className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                ) : (
                  <svg
                    className="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                )}

                {item.task}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default InProgress;
