import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const Paginator = ({ items, setData, itemsPerPage = 8 }) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items?.slice(itemOffset, endOffset);

  useEffect(() => {
    setData(currentItems);
  }, [itemOffset, items]);

  const pageCount = Math.ceil(items?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };
  return (
    <div>
      <ReactPaginate
        // breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        nextLabel={
          <div className="p-4 bg-white/50 backdrop-blur-[18px] rounded-full">
            <img className="rotate-180" width="15px" src="/icons/back.png" />
          </div>
        }
        previousLabel={
          <div className="p-4 bg-white/50 backdrop-blur-[18px] rounded-full">
            <img width="15px" src="/icons/back.png" />
          </div>
        }
        containerClassName="flex items-center gap-4 text-lg font-medium text-gray-500"
        activeClassName="text-primary font-bold"
      />
    </div>
  );
};

export default Paginator;
