import Cookies from "js-cookie";
import { HTPApi } from "../api/ApiConfig";

export const paymentHandler = async (
  paymentSettings,
  amount,
  currency,
  callback
) => {
  const key_id = paymentSettings.razor_key_id;
  const key_secret = paymentSettings.razor_key_secret;
  const lab_name = paymentSettings.lab_name;
  console.log(paymentSettings);

  const sessionUrl = "/api/create-razorpay-session/";
  const sessionBody = { amount, currency };

  const response = await HTPApi.post(sessionUrl, sessionBody);

  const sessionData = response.data.data;
  console.log(sessionData);

  const rzpOptions = {
    key: key_id,
    amount: sessionData.amount,
    currency: sessionData.currency,
    name: lab_name,
    description: "Payment for your service",
    order_id: sessionData.id,
    handler: async (paymentRes) => {
      const verificationUrl = "/api/verify-razorpay-transaction/";

      try {
        const verificationResult = await HTPApi.post(
          verificationUrl,
          paymentRes
        );
        console.log(verificationResult.data);
        callback(verificationResult.data, paymentRes);
      } catch (error) {
        console.log(error);
      }
    },
  };

  const rzp = new window.Razorpay(rzpOptions);
  rzp.open();
};
