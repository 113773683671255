import { Popover, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { HTPAppContext } from "../../context/HTPContext";
import { checkUserExistance } from "../../services/clientServices";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

const ProfilePopOver = () => {
  const { firebaseUID, isLoggedIn, logout } = useContext(HTPAppContext);
  const [userData, setUserData] = useState({
    name: null,
    phone: null,
  });

  const navigate = useNavigate();
  const { removeAccess } = useUser();

  useEffect(() => {
    const findUser = async () => {
      try {
        const response = await checkUserExistance(firebaseUID);
        setUserData({
          name: response.data.user.name,
          phone: response.data.user.phone,
        });
      } catch (error) {
        console.log(error);
      }
    };
    findUser();
  }, [firebaseUID, isLoggedIn]);

  const handleLogout = () => {
    removeAccess();
  };

  return (
    <div>
      <Popover className="relative">
        <Popover.Button className="outline-none ">
          <div className="flex rounded-[24px] px-6 py-1 border cursor-pointer bg-white">
            <span>
              Hi, {userData.name ? userData.name.split(" ")[0] : "user"}
            </span>
          </div>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition duration-200 ease-out"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition duration-100 ease-in"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute z-10 px-2 lg:max-w-md bg-white -left-10 min-w-[220px] rounded-md">
            <div className="py-2">
              <div className="text-center p-4 border-b border-slate-300">
                <div className="text-lg font-bold">
                  {userData.name ? userData.name : "user"}
                </div>
                <div className="text-normal font-normal">
                  {userData.phone ? `+91 ${userData.phone}` : "Phone number"}
                </div>
              </div>
              <div className="">
                {/* <div className="flow-root cursor-pointer rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/50">
                  View Reports
                </div> */}
                <div
                  onClick={() => navigate("/orders")}
                  className="flow-root cursor-pointer rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/50"
                >
                  View Orders
                </div>
                <div
                  onClick={() => handleLogout()}
                  className="flow-root cursor-pointer rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/50"
                >
                  Logout
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default ProfilePopOver;
