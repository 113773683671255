import { combineReducers } from "@reduxjs/toolkit";
import cartReducer from "../cart/cartSlice";
import patientReducer from "../patient/patientSlice";
import testsReducer from "../tests/testsSlice";
import labSpecs from "../lab/labSpecs";

const rootReducer = combineReducers({
  cart: cartReducer,
  patient: patientReducer,
  testPackage: testsReducer,
  labSpecs: labSpecs,
});

export default rootReducer;
