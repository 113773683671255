import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  authenticate,
  getConfirmationResult,
} from "../../firebase/firebaseUtils";
import { HTPAppContext } from "../../context/HTPContext";
import ClipLoader from "react-spinners/ClipLoader";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import Cookies from "js-cookie";
import { checkUserExistance } from "../../services/clientServices";
import { auth } from "../../firebase/firebaseInit";
import useUser from "../../hooks/useUser";

const AuthModal = () => {
  const {
    setIsAuthDialogOpen,
    isAuthDialogOpen,
    setModalType,
    modalType,
    setUserDataContext,
    setLabName,
    setFirbaseUID,
    setIsRegisterModalOpen,
    setToken,
    setUserPhone,
  } = useContext(HTPAppContext);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState();
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOtp] = useState(null);

  const { setAccess, registerUser } = useUser();

  const closeModal = () => {
    setShowOTPInput(false);
    setIsAuthDialogOpen(false);
    reset();
    setOtp(null);
  };

  const onSubmit = async function (data) {
    setLoading(true);
    const formValues = getValues();
    const countryCode = "+91";
    const firebaseStylePhone = countryCode + formValues.phone;

    try {
      if (otp === null) {
        const response = await getConfirmationResult(firebaseStylePhone);
        if (response) {
          setConfirmationResult(response);
          setShowOTPInput(true);
        }
      } else {
        if (confirmationResult) {
          const user = await authenticate(confirmationResult, otp);
          console.log(user);
          const idToken = await user?.user?.getIdTokenResult();
          const firebaseUid = user?.user?.uid;
          setToken(idToken.token);
          setFirbaseUID(firebaseUid);
          setUserPhone(formValues.phone);

          if (idToken) {
            try {
              const response = await checkUserExistance(firebaseUid);
              const userExists = response.data.exists;
              if (response.data.exists) {
                // Arguments 1. firebase confirmation (user) 2. checkUserExistance's response.data
                setAccess(user, response.data);
              } else {
                //  Arguments 1. firebase confirmation (user)
                registerUser(user);
              }
            } catch (error) {
              toast.error("not a user");
              console.log(error);
            } finally {
              setOtp(null);
              closeModal();
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition show={isAuthDialogOpen}>
      <Dialog
        open={isAuthDialogOpen}
        onClose={() => closeModal()}
        className="relative z-50 select-none"
      >
        <div
          className="fixed inset-0 bg-black/25 backdrop-blur-md"
          aria-hidden="true"
        />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center p-3">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl overflow-hidden rounded-2xl bg-white p-3 text-left h-auto md:h-[450px] flex items-center">
                <div className="grid sm:grid-cols-2 grid-cols-1 w-full">
                  <div className="hidden sm:flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={`https://${window.location.hostname}/static/assets/HomeTest.png`}
                        alt=""
                      />
                    </div>
                    <div className="mt-5 font-bold text-[#333] text-xl">
                      Lab Tests at Home
                    </div>
                    <div className="text-sm text-center py-3 px-10 text-slate-700">
                      Book any test from Lab. We'll collect the sample and send
                      the reports. Save up to 80% every time
                    </div>
                  </div>
                  <div className="px-5 md:border-l-2 my-10 md:my-0">
                    <div className="font-bold text-3xl">
                      {!showOTPInput
                        ? modalType === "login"
                          ? "Login"
                          : "Sign Up"
                        : "Verify OTP"}
                    </div>
                    <div className="text-sm my-3">
                      {!showOTPInput
                        ? "Please enter your 10 digits Mobile number to receive OTP"
                        : "Enter six digit OTP sent to your mobile"}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {!showOTPInput ? (
                        <>
                          <input
                            type="number"
                            inputMode="numeric"
                            placeholder="Enter Mobile Number"
                            className="appearance-none w-full p-3 outline-none border-b-2 border-primary [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                            {...register("phone", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Must be of 10 digits length",
                              },
                            })}
                          />
                          {errors.phone && (
                            <p className="text-red-500">
                              {errors.phone.message}
                            </p>
                          )}
                        </>
                      ) : (
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          containerStyle="mt-5 w-full"
                          renderSeparator={<span>-</span>}
                          shouldAutoFocus
                          renderInput={(props, index) => (
                            <input
                              {...props}
                              type="number"
                              className="border border-gray-300 text-[40px] md:text-[50px] rounded-md outline-none select-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                            />
                          )}
                        />
                      )}
                      <button
                        type="submit"
                        className="w-full bg-primary rounded-md mt-10 text-white font-medium text-center text-xl p-3 cursor-pointer"
                      >
                        {loading ? (
                          <ClipLoader
                            size={20}
                            color={"white"}
                            loading={loading}
                          />
                        ) : (
                          <span>
                            {!showOTPInput
                              ? modalType === "login"
                                ? "Login"
                                : "Sign Up"
                              : "Verify"}
                          </span>
                        )}
                      </button>
                    </form>

                    <div
                      className="text-center mt-5 cursor-pointer"
                      onClick={() => {
                        setModalType((prev) =>
                          prev === "login" ? "register" : "login"
                        );
                      }}
                    >
                      {modalType === "login"
                        ? "Do not have an account?"
                        : "Have an account?"}{" "}
                      <span className="text-primary font-medium">
                        {modalType === "login" ? "Sign Up" : "Login"}
                      </span>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthModal;
