import { HTPApi } from "../api/ApiConfig";
const baseEndpoint = "/api/package";

export const searchTestPackages = async (query) => {
  const response = await HTPApi.get(`${baseEndpoint}/search/`, {
    params: { q: query },
  });
  return response;
};

export const findTestPackageById = async (id) => {
  const response = await HTPApi.get(`${baseEndpoint}/labtestpackage/${id}/`);
  return response;
};

export const findSingleTestById = async (id) => {
  const response = await HTPApi.get(`${baseEndpoint}/labtest/${id}/`);
  return response;
};
