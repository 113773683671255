import { Dialog } from "@headlessui/react";
import {
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { HTPAppContext } from "../../context/HTPContext";
import { useSelector } from "react-redux";
import useFindApi from "../../hooks/useFindApi";
import {
  findAllAddress,
  deleteAddressById,
} from "../../services/clientServices";
import toast from "react-hot-toast";

const SelectAddress = forwardRef((props, ref) => {
  const { setIsCartStepOpen, setStep, setPatientDetails, patientDetails } =
    useContext(HTPAppContext);
  const patientState = useSelector((state) => state.patient);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // const { data, loading } = useFindApi("/api/clients/address/");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAddress();
    const addressAlreadySelected = patientState.address.id;
    if (addressAlreadySelected) {
      setSelectedAddress(patientState.address);
    }
  }, []);

  const fetchAddress = () => {
    setLoading(true);
    findAllAddress()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Address Error : ${err.message}`);
      })
      .finally(setLoading(false));
  };

  const handleSelectAddress = (address) => {
    if (address) {
      console.log(address);
      setPatientDetails({ ...patientDetails, address });
      setStep((prev) => prev + 1);
    }
  };

  const handleDeleteAddress = (id) => {
    deleteAddressById(id)
      .then((res) => {
        toast.success("Address deleted");
        fetchAddress();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Address error : ${err.message}`);
      });
  };

  return (
    <div>
      <Dialog.Panel
        ref={ref}
        className="w-[300px] md:w-[417px] overflow-hidden rounded-2xl bg-modelBgCart p-6 shadow-xl"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div
              className="cursor-pointer me-4"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <img
                src={`https://${window.location.hostname}/static/assets/Back.png`}
                alt="back"
              />
            </div>
            <div className="text-xl font-medium">Address Details</div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsCartStepOpen(false);
              setStep(1);
            }}
          >
            <img
              src={`https://${window.location.hostname}/static/assets/Close.png`}
              alt="close"
            />
          </div>
        </div>
        <div className={`my-3 bg-white h-[10px]`}>
          <div className="w-[66%] bg-primary h-[10px] rounded-lg transition-all duration-150"></div>
        </div>
        <div className="text-start space-y-2">
          <div className=" font-bold text-xl pt-2">Select Address</div>
          <div className=" font-medium ">
            A phlebotomist will visit you to collect samples.
          </div>
          <div className="overflow-y-scroll h-[300px] space-y-2 font-medium">
            {loading && <div className="font-medium text-lg">Loading...</div>}
            {data?.length < 1 && !loading ? (
              <div className="h-full flex justify-center items-center font-medium text-lg text-primary ">
                No address found
              </div>
            ) : (
              data?.map((address) => {
                // const isAddressSelected = patientState?.address?.id ? patientState.address.id === selectedAddress.id : false
                const isAddressSelected = selectedAddress?.id
                  ? selectedAddress.id === address.id
                  : false;
                return (
                  <div
                    className="bg-white text-start py-4 px-6 rounded-lg me-2 flex items-center"
                    key={address.id}
                  >
                    <div className="me-4">
                      <input
                        id="push-email"
                        name="push-notifications"
                        type="radio"
                        className="h-5 w-5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={() => {
                          setSelectedAddress(address);
                        }}
                        checked={isAddressSelected}
                      />
                    </div>
                    <div className="font-medium leading-6">
                      <div>{`${address.flat} ${address.landmark} ${address.city} ${address.state}, ${address.pin}`}</div>
                    </div>
                    <div className="ms-auto">
                      <a
                        onClick={() => handleDeleteAddress(address.id)}
                        className="cursor-pointer"
                      >
                        <img
                          width={"18px"}
                          src={`https://${window.location.hostname}/static/assets/Delete.png`}
                        />
                      </a>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div
          onClick={() => setStep(5)}
          className="text-right font-medium text-secondary cursor-pointer mt-6"
        >
          + Add New Address
        </div>
        <div
          onClick={() => handleSelectAddress(selectedAddress)}
          className="w-full text-xl text-center font-medium text-white bg-primary rounded-2xl mt-6 p-[16px] cursor-pointer"
        >
          Select Address
        </div>
      </Dialog.Panel>
    </div>
  );
});

export default SelectAddress;
