
import { Dialog, Transition } from "@headlessui/react";
import { useContext } from "react";
import SelectPatient from "./SelectPatient";
import SelectAddress from "./SelectAddress";
import SelectSchedule from "./SelectSchedule";
import { HTPAppContext } from "../../context/HTPContext";
import AddPatient from "./AddPatient";
import AddAddress from "./AddAddress";

const CartSteps = () => {
    const { step, isCartStepOpen } = useContext(HTPAppContext);
    const showStep = function (step) {
        switch (step) {
            case 1:
                return <SelectPatient />;
            case 2:
                return <SelectAddress />;
            case 3:
                return <SelectSchedule />;
            case 4:
                return <AddPatient />;
            case 5:
                return <AddAddress />;
        }
    };

    return (
        <Transition show={isCartStepOpen}>
            <Dialog
                open={isCartStepOpen}
                onClose={() => { }}
                className="relative z-50 select-none"
            >
                <div
                    className="fixed inset-0 bg-black/25 backdrop-blur-md"
                    aria-hidden="true"
                />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={"div"}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            {showStep(step)}
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default CartSteps;
