const Features = () => {
  return (
    <section className="max-w-7xl mx-auto px-10 mt-0 tracking-wide mb-32">
      <div className="text-[28px] md:text-5xl text-center lg:text-left font-bold sm:mb-14">
        Lab Test from the comfort of your home
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-9 gap-4 justify-center text-lg">
        <div className="text-center mt-10 sm:mt-0">
          <img
            src={`https://${window.location.hostname}/static/assets/Protect.png`}
            alt=""
            className="mx-auto mb-4"
          />
          <div className="font-bold w-1/3 mx-auto md:w-full">
            100% Safe & Hygienic
          </div>
        </div>
        <div className="hidden lg:flex lg:justify-center">
          <img
            src={`https://${window.location.hostname}/static/assets/Border.png`}
            alt=""
            className=""
          />
        </div>
        <div className="text-center mt-10 sm:mt-0">
          <img
            src={`https://${window.location.hostname}/static/assets/Doctor.png`}
            alt=""
            className="mx-auto mb-4"
          />
          <div className="font-bold w-1/3 mx-auto md:w-full">
            Free Doctor Consultation
          </div>
        </div>
        <div className="hidden lg:flex lg:justify-center">
          <img
            src={`https://${window.location.hostname}/static/assets/Border.png`}
            alt=""
            className=""
          />
        </div>
        <div className="text-center mt-10 sm:mt-0">
          <img
            src={`https://${window.location.hostname}/static/assets/Price.png`}
            alt=""
            className="mx-auto mb-4"
          />
          <div className="font-bold w-1/3 mx-auto md:w-full">
            Best Price Guaranteed
          </div>
        </div>
        <div className="hidden lg:flex lg:justify-center">
          <img
            src={`https://${window.location.hostname}/static/assets/Border.png`}
            alt=""
            className=""
          />
        </div>
        <div className="text-center mt-10 sm:mt-0">
          <img
            src={`https://${window.location.hostname}/static/assets/Laptop.png`}
            alt=""
            className="mx-auto mb-4"
          />
          <div className="font-bold w-1/3 mx-auto md:w-full">
            View Reports Online
          </div>
        </div>
        <div className="hidden lg:flex lg:justify-center">
          <img
            src={`https://${window.location.hostname}/static/assets/Border.png`}
            alt=""
            className=""
          />
        </div>
        <div className="text-center mt-10 sm:mt-0">
          <img
            src={`https://${window.location.hostname}/static/assets/Delivery.png`}
            alt=""
            className="mx-auto mb-4"
          />
          <div className="font-bold w-1/3 mx-auto md:w-full">
            Sample Pick up
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
