const OrdersSkeleton = () => {
  return (
    <>
      {new Array(20).fill(0).map((element) => {
        return (
          <div
            role="status"
            key={Math.random()}
            className=" bg-slate-200 backdrop-blur-[18px] px-4 py-8 rounded-lg my-4 "
          >
            <div className="w-full bg-slate-200 rounded-lg px-3 py-2">
              <div class="grid grid-cols-3 gap-2">
                <div class="col-span-2 font-medium mb-4">
                  <div className="bg-slate-300 backdrop-blur-[18px] py-2 rounded-lg animate-pulse w-[50%]"></div>
                </div>
                <div class="col-span-1 mb-4">
                  <div className="bg-slate-300 backdrop-blur-[18px] py-2 rounded-lg animate-pulse  w-[50%]"></div>
                </div>

                <div class="text-right font-medium">
                  <div className="bg-slate-300 backdrop-blur-[18px] py-2 rounded-lg animate-pulse  w-[50%]"></div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrdersSkeleton;
