import { HTPApi } from "../api/ApiConfig";

const baseUrl = "/api/orders/";

export const createOrder = async (data) => {
  const response = await HTPApi.post(baseUrl, data);
  return response;
};

export const findOrders = async (id) => {
  const response = await HTPApi.get(baseUrl, { params: { id } });
  return response;
};

export const findOrderById = async (id) => {
  const response = await HTPApi.get(`${baseUrl}${id}/`);
  return response;
};
