import { useEffect, useState } from "react";
import {
  CTA,
  Features,
  Footer,
  Hero,
  Navbar,
  PopularCheckup,
} from "../components";
import PopularTests from "../components/landing/PopularTests";

const Landing = () => {
  return (
    <>
      <Navbar />
      <main className="select-none">
        <Hero />
        <PopularTests />
        <PopularCheckup />
        {/* <CTA /> */}
        <Features />
        <div className="htp_landing_blue_one overflow-hidden absolute  right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default Landing;
