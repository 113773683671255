import { useContext, useEffect, useState } from "react";
import { HTPAppContext } from "../../context/HTPContext";
import CheckupCard from "../common/CheckupCard";
import Chips from "./Chips";
import HTPSkeleton from "../skeleton/HTPSkeleton";
import { searchTestPackages } from "../../services/pakageServices";
import toast from "react-hot-toast";
import { testChips } from "../../data/HTPData";
import { findInGlobalSearch } from "../../services/globalServices";
import { convertToQueryFormat } from "../../utils/HTPUtils";

const PopularTests = () => {
  const [loading, setLoading] = useState(false);
  const { selectedTestChip, setSelectedTestChip } = useContext(HTPAppContext);
  const [queriedLabTests, setQueriedLabTests] = useState([]);

  useEffect(() => {
    const searchLabTests = async () => {
      setLoading(true);
      try {
        // temperaory solution for popular packages
        const response = await findInGlobalSearch(
          selectedTestChip === "Popular Tests"
            ? "popular"
            : convertToQueryFormat(selectedTestChip),
          "test"
        );
        setQueriedLabTests(response.data);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    searchLabTests();
  }, [selectedTestChip]);

  return (
    <section className="max-w-7xl mx-auto px-1 my-[110px] relative">
      <div className="text-[28px] md:text-[50px] text-center lg:text-left font-bold">
        Popular Health Tests
      </div>
      <Chips
        chips={testChips}
        selectedChip={selectedTestChip}
        setChip={setSelectedTestChip}
      />
      {loading ? <HTPSkeleton /> : <CheckupCard data={queriedLabTests} />}
      {queriedLabTests?.length < 1 && !loading && (
        <div className="h-[250px] p-4 bg-gray-300 rounded-xl flex justify-center items-center">
          <h1 className="font-medium text-2xl text-primary">
            No Tests Available
          </h1>
        </div>
      )}
      <div className="htp_landing_blue_two absolute -left-[1200px] top-0 overflow-hidden"></div>
    </section>
  );
};

export default PopularTests;
