import { categories } from "../../data/HTPData";
import CategoryCard from "./CategoryCard";
import useFetch from "../../hooks/useFetch";
import CategoriesSkeleton from "../skeleton/CategoriesSkeleton";
import { useState } from "react";
import GoBackButton from "../common/GoBackButton";

const AllCategories = () => {
  const [data, setData] = useState(categories);

  return (
    <section className="max-w-7xl mb-8 mx-auto relative">
      <div className="ps-4 pb-2">
        <GoBackButton />
      </div>
      <div className="font-bold text-4xl md:text-5xl mx-5 text-center md:text-left">
        All Categories
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-10 gap-[20px] mx-5">
        {false ? (
          <CategoriesSkeleton />
        ) : (
          <>
            {data.map((category, index) => {
              return <CategoryCard category={category} key={index} />;
            })}
          </>
        )}
      </div>
      <div className="htp_landing_blue_two overflow-hidden absolute -left-[1100px] top-0"></div>
    </section>
  );
};

export default AllCategories;
