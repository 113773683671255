import React, { useCallback } from "react";
import useCart from "../../hooks/useCart";

const SearchBarAtcButton = ({ active, item }) => {
  const { isAddedToCart, addProductToCart } = useCart();
  const productType = item.tests ? "package" : "single";
  const added = isAddedToCart(item.id, productType);

  function handleAddToCart(event) {
    event.preventDefault();
    addProductToCart(item);
  }

  const handleClick = useCallback((event) => {
    handleAddToCart(event);
  }, []);

  return (
    <div>
      <button
        onClick={handleClick}
        className={`font-medium border-[1px] px-2 py-1 rounded-full ${
          active ? "border-white text-white" : "border-primary text-primary"
        } ${added ? "bg-primary text-white" : "border-primary text-primary"}`}
      >
        {added ? "Added" : "Add Test"}
      </button>
    </div>
  );
};

export default SearchBarAtcButton;
