import { HTPApi } from "../api/ApiConfig";

const baseEndpoint = "/api/clients";

export const checkUserExistance = async (query) => {
  const response = await HTPApi.get(`${baseEndpoint}/check_user_existence/`, {
    params: { q: query },
  });
  return response;
};

export const createPatient = async (data) => {
  const response = await HTPApi.post(`${baseEndpoint}/patient/`, data);
  return response;
};

export const findAllPatient = async () => {
  const response = await HTPApi.get(`${baseEndpoint}/patient/`);
  return response;
};

export const findPatientById = async (id) => {
  const response = await HTPApi.get(`${baseEndpoint}/patient/${id}/`);
  return response;
};

export const deletePatientById = async (id) => {
  const response = await HTPApi.delete(`${baseEndpoint}/patient/${id}/`);
  return response;
};

export const createAddress = async (data) => {
  const response = await HTPApi.post(`${baseEndpoint}/address/`, data);
  return response;
};

export const findAllAddress = async () => {
  const response = await HTPApi.get(`${baseEndpoint}/address/`);
  return response;
};

export const findAddressById = async (id) => {
  const response = await HTPApi.get(`${baseEndpoint}/address/${id}/`);
  return response;
};

export const deleteAddressById = async (id) => {
  const response = await HTPApi.delete(`${baseEndpoint}/address/${id}/`);
  return response;
};

export const createUser = async (data) => {
  const response = await HTPApi.post(`${baseEndpoint}/user/`, data);
  return response;
};

export const findUserByID = async (id) => {
  const response = await HTPApi.get(`${baseEndpoint}/user/${id}/`);
  return response;
};
