import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import { store } from "./redux/store";
import ReactDOM from "react-dom/client";
import App from "./App";
import HTPContext from "./context/HTPContext";
import "./css/global.css";
import "./css/custom.css";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HTPContext>
          <App />
        </HTPContext>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
