import { forwardRef, useContext, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { HTPAppContext } from "../../context/HTPContext";
import { useSelector } from "react-redux";
import useFindApi from "../../hooks/useFindApi";
import {
  deletePatientById,
  findAllPatient,
} from "../../services/clientServices";
import toast from "react-hot-toast";

const SelectPatient = forwardRef((props, ref) => {
  const { setIsCartStepOpen, setStep, step, setPatientDetails } =
    useContext(HTPAppContext);
  const patientState = useSelector((state) => state.patient);
  const [selectedPatient, setSelectedPatient] = useState();
  // const { data, loading } = useFindApi("/api/clients/patient/");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPatients();
    setSelectedPatient(patientState.patients);
  }, []);

  const fetchPatients = () => {
    setLoading(true);
    findAllPatient()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Patinets Error : ${err.message}`);
      })
      .finally(setLoading(false));
  };

  const handleSelectPatient = () => {
    if (selectedPatient.length > 0) {
      const updatedDetails = {
        patients: [...selectedPatient],
      };
      setPatientDetails(updatedDetails);
      setStep((prev) => prev + 1);
    }
  };

  const handleDeletePatient = (id) => {
    deletePatientById(id)
      .then((res) => {
        toast.success("Patinet deleted");
        fetchPatients();
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Patient error : ${error.message}`);
      });
  };

  return (
    <div>
      <Dialog.Panel
        ref={ref}
        className="w-[300px] md:w-[417px] overflow-hidden rounded-2xl bg-modelBgCart p-6 shadow-xl"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div
              className="cursor-pointer me-4"
              onClick={() =>
                step === 1
                  ? setIsCartStepOpen(false)
                  : setStep((prev) => prev - 1)
              }
            >
              <img
                src={`https://${window.location.hostname}/static/assets/Back.png`}
                alt="back"
              />
            </div>
            <div className="text-xl font-medium">Patient Details</div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsCartStepOpen(false);
              setStep(1);
            }}
          >
            <img
              src={`https://${window.location.hostname}/static/assets/Close.png`}
              alt="close"
            />
          </div>
        </div>
        <div className="my-3 bg-white h-[10px] rounded-lg">
          <div className="w-[33%] bg-primary h-[10px] rounded-lg"></div>
        </div>
        <div className="text-start space-y-2">
          <div className=" font-bold text-xl pt-2">Select Patient</div>
          <div className=" font-medium ">
            Please select all the patient for whom you want to book the tests.
            Address for all patients should be same.
          </div>
          <div className="overflow-y-scroll h-[300px] space-y-2">
            {loading && <div className="font-medium text-lg">Loading...</div>}

            {data?.length < 1 && !loading ? (
              <div className="h-full flex justify-center items-center font-medium text-lg text-primary ">
                No patinet found
              </div>
            ) : (
              data?.map((patient, index) => {
                const isPatientSelected = selectedPatient.some(
                  (selected) => selected.id === patient.id
                );
                return (
                  <div
                    className="bg-white text-start py-2 px-6 rounded-lg me-2 flex items-center"
                    key={index}
                  >
                    <div className="me-4">
                      <input
                        type="checkbox"
                        name="patient"
                        className="border border-black h-[20px] w-[20px]"
                        checked={isPatientSelected}
                        onChange={() => {
                          const isPatientSelected = selectedPatient.find(
                            (selected) => selected.id === patient.id
                          );

                          if (isPatientSelected) {
                            // If patient is already selected, remove it
                            setSelectedPatient((prevSelectedPatients) =>
                              prevSelectedPatients.filter(
                                (selected) => selected.id !== patient.id
                              )
                            );
                          } else {
                            // Otherwise, add it to the selected patients
                            setSelectedPatient((prevSelectedPatients) => [
                              ...prevSelectedPatients,
                              patient,
                            ]);
                          }
                        }}
                      />
                    </div>
                    <div className="leading-5 font-medium">
                      <div>
                        {patient.name} <br />
                        {patient.age}, {patient.gender}
                      </div>
                    </div>
                    <div className="ms-auto">
                      <a
                        onClick={() => handleDeletePatient(patient.id)}
                        className="cursor-pointer"
                      >
                        <img
                          width={"18px"}
                          src={`https://${window.location.hostname}/static/assets/Delete.png`}
                        />
                      </a>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div
          onClick={() => setStep(4)}
          className="text-right cursor-pointer font-medium text-secondary mt-6"
        >
          + Add New Patient
        </div>
        <div
          onClick={() => handleSelectPatient()}
          className="w-full text-xl text-center font-medium text-white bg-primary rounded-2xl mt-6 p-[16px]"
        >
          Select Patient
        </div>
      </Dialog.Panel>
    </div>
  );
});

export default SelectPatient;
