import { Virtual, Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { dummyPromotion } from "../../data/HTPData";
import Loader from "../loader/Loader";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addToCart, selectTests } from "../../redux/cart/cartSlice";
import {
  findQuickbookTests,
  findPromotions,
} from "../../services/promotionServices";

const Hero = () => {
  const [quickBookTests, setQuickBookTests] = useState(null);
  const [promotionData, setPromotionData] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const cartTests = useSelector(selectTests);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPromotionsAndQuickbook = async () => {
      setLoading(true);
      try {
        const quickBookResponce = await findQuickbookTests();
        const promotionResponce = await findPromotions();
        setPromotionData(promotionResponce.data);

        const responce = quickBookResponce.data;
        const newArr = [];
        responce?.forEach((element) => {
          if (element.test != null) {
            newArr.push(element.test);
          }
          if (element.package != null) {
            newArr.push(element.package);
          }
        });
        setQuickBookTests(newArr);
      } catch (error) {
        console.log(error);
        toast.error(`${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPromotionsAndQuickbook();
  }, []);

  const handleCtaClick = () => {};

  const isAddedToCart = (productId) => {
    return cartTests.some((test) => test.element.id === productId);
  };

  const handleAddToCart = (product, added) => {
    if (added) {
      toast.error("Already added to the cart");
      return;
    }
    toast.success("Test Added");
    dispatch(addToCart({ element: product }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="max-w-7xl mx-auto mt-[50px] mb-[100px]">
      <div className="grid grid-cols-1 lg:grid-cols-2 mx-3">
        <div className="relative">
          <Swiper
            modules={[Virtual, Navigation, Pagination, Autoplay]}
            centeredSlides={false}
            spaceBetween={50}
            autoplay={{
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              el: ".swiper-custom-pagination",
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
            }}
            className="htp_landing_slider lg:w-[620px] bg-white/20 backdrop-blur-[18px] w-full z-10"
          >
            {!promotionData || promotionData?.length < 1 ? (
              <div className="h-[250px] mt-2 p-4 flex justify-center items-center">
                <h1 className="font-medium text-2xl text-primary">
                  No Promotions
                </h1>
              </div>
            ) : (
              <>
                {promotionData?.map((slideContent, index) => (
                  <React.Fragment key={index}>
                    {slideContent.promotion_image ? (
                      <SwiperSlide key={index} virtualIndex={index}>
                        <div className="">
                          <img
                            src={slideContent.promotion_image}
                            className="object-contain"
                            // alt="Promotion image"
                          />
                        </div>
                      </SwiperSlide>
                    ) : (
                      <SwiperSlide
                        key={index}
                        virtualIndex={index}
                        className="p-[30px] sm:px-[40px] sm:py-[50px]"
                      >
                        <div className="text-[30px] sm:text-6xl font-bold text-center sm:text-left promotion_image ">
                          {slideContent.promotion_title}
                        </div>
                        <div className="flex flex-col-reverse items-center sm:flex-row mt-5 sm:justify-between">
                          <div className="font-medium w-2/4 text-xl sm:text-xl sm:self-center text-center sm:text-left mt-5 mr-8 sm:mt-0">
                            {slideContent.promotion_description}
                          </div>
                          {slideContent.punch_text && (
                            <>
                              <div className="w-2/4 text-secondary font-bold text-2xl pl-4">
                                <div className="flex space-x-2 items-baseline">
                                  <div className="text-5xl flex-wrap">
                                    {slideContent.punch_text}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {slideContent.cta_url && (
                          <Link
                            to={slideContent.cta_url}
                            target="_blank"
                            className="flex justify-between mt-[40px]"
                          >
                            <div className="bg-primary text-white font-medium px-[40px] py-[10px] rounded-[73px] transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer">
                              {slideContent.cta_label}
                            </div>
                            <div className="swiper-custom-pagination w-fit space-x-3 items-center self-center justify-center hidden"></div>
                          </Link>
                        )}
                      </SwiperSlide>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </Swiper>
          <div className="absolute -top-10 -left-10 w-1/3 md:w-full">
            <img
              src={`https://${window.location.hostname}/static/assets/SliderBgOne.png`}
              alt=""
            />
          </div>
          <div className="absolute -bottom-5 -right-0">
            <img
              src={`https://${window.location.hostname}/static/assets/SliderBgTwo.png`}
              alt=""
            />
          </div>
        </div>
        <div className="mx-3 md:ml-28 xl:ml-20 mt-20">
          <div className="flex justify-between">
            <div className="flex items-center space-x-2">
              <div className="font-bold text-2xl">Quick Book</div>
              <div>
                <img
                  src={`https://${window.location.hostname}/static/assets/Star.png`}
                  alt=""
                />
              </div>
            </div>
            <div
              onClick={() => navigate("/packages/all")}
              className="font-bold text-secondary cursor-pointer z-10"
            >
              Find Your Test
            </div>
          </div>

          {!quickBookTests || quickBookTests?.length < 1 ? (
            <div className="h-[250px] mt-2 p-4 bg-gray-300 rounded-xl flex justify-center items-center">
              <h1 className="font-medium text-2xl text-primary">
                No Quickbook Test Available
              </h1>
            </div>
          ) : (
            <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-10  font-medium">
              {quickBookTests?.slice(0, 3).map((item, index) => {
                const productId = item.id;
                const added = isAddedToCart(productId);

                return (
                  <div key={index} className="text-sm ">
                    <div
                      onClick={() => handleAddToCart(item, added)}
                      className={`flex space-x-2 cursor-pointer ${
                        added && "text-green-600"
                      }`}
                    >
                      <div className="">
                        <img
                          src={`https://${window.location.hostname}/static/assets/Add.png`}
                          className=" max-w-max"
                          alt=""
                        />
                      </div>
                      <div>{item.name || item.name}</div>
                    </div>
                  </div>
                );
              })}
              {quickBookTests?.slice(3, 6).map((item, index) => {
                const productId = item.id;
                const added = isAddedToCart(productId);

                return (
                  <div key={index} className="text-sm ">
                    <div
                      onClick={() => handleAddToCart(item, added)}
                      className={`flex space-x-2 cursor-pointer ${
                        added && "text-green-600"
                      }`}
                    >
                      <div className="">
                        <img
                          src={`https://${window.location.hostname}/static/assets/Add.png`}
                          className=" max-w-max"
                          alt=""
                        />
                      </div>
                      <div>{item.name || item.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* {quickBookTests?.length > 0 && (
            <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-10  font-medium">
              {quickBookTests?.slice(0, 3).map((item, index) => {
                const productId = item.id;
                const added = isAddedToCart(productId);

                return (
                  <div key={index} className="text-sm ">
                    <div
                      onClick={() => handleAddToCart(item, added)}
                      className={`flex space-x-2 cursor-pointer ${
                        added && "text-green-600"
                      }`}
                    >
                      <div className="">
                        <img
                          src={`https://${window.location.hostname}/static/assets/Add.png`}
                          className=" max-w-max"
                          alt=""
                        />
                      </div>
                      <div>{item.name || item.name}</div>
                    </div>
                  </div>
                );
              })}
              {quickBookTests?.slice(3, 6).map((item, index) => {
                const productId = item.id;
                const added = isAddedToCart(productId);

                return (
                  <div key={index} className="text-sm ">
                    <div
                      onClick={() => handleAddToCart(item, added)}
                      className={`flex space-x-2 cursor-pointer ${
                        added && "text-green-600"
                      }`}
                    >
                      <div className="">
                        <img
                          src={`https://${window.location.hostname}/static/assets/Add.png`}
                          className=" max-w-max"
                          alt=""
                        />
                      </div>
                      <div>{item.name || item.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
