import React from "react";
import { Footer, Navbar } from "../components";
import PurchaseCompleteMain from "../components/purchaseComplete/PurchaseCompleteMain";
import { Link } from "react-router-dom";

const PurchaseComplete = () => {
  return (
    <>
      <Navbar />
      <main className="select-none">
        <PurchaseCompleteMain />
        <div className="htp_landing_blue_one overflow-hidden absolute right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default PurchaseComplete;
