import React from "react";

const WarningBand = ({ message, type = "danger" }) => {
  return (
    <div
      className={`py-1.5 px-4 w-full border-l-4 border-b ${
        type === "danger"
          ? " border-red-700 bg-red-200/50  text-red-700"
          : "border-orange-700 bg-orange-200/50 text-yellow-700"
      }`}
    >
      <div className="font-medium text-sm mx-auto">{message}</div>
    </div>
  );
};

export default WarningBand;

// " border-red-700 bg-red-500/50  text-red-700"
