import { useForm } from "react-hook-form";
import GoBackButton from "../common/GoBackButton";
import { createQuery } from "../../services/queryServices";
import toast from "react-hot-toast";
import { useContext } from "react";
import HTPContext, { HTPAppContext } from "../../context/HTPContext";

const ContactSection = () => {
  const { token } = useContext(HTPAppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitForm = (data) => {
    console.log(data);
    if (!token) {
      toast.error("You need to login first!");
    }

    createQuery(data)
      .then((res) => {
        console.log(res);
        toast.success("Query Sent");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  return (
    <section className="max-w-7xl mx-auto mt-20 mb-24 relative">
      <GoBackButton />
      <h1 className="font-bold text-4xl md:text-5xl mx-5 text-center md:text-left">
        Contact Us
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 relative">
        <div className="col-span-2 bg-white p-5 mt-10 rounded-3xl mx-5 order-2 lg:order-1">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="p-5 rounded-2xl bg-contactInput">
                <div className="flex space-x-4 items-center">
                  <img
                    src={`https://${window.location.hostname}/static/assets/user.png`}
                    alt=""
                  />
                  <div className="flex-1">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                      {...register("first_name", {
                        required: "First name is required",
                      })}
                    />
                  </div>
                </div>
                <div className="text-red-700 ml-5 mt-3">
                  {errors.first_name?.message?.toString()}
                </div>
              </div>
              <div className="p-5 rounded-2xl bg-contactInput">
                <div className="flex space-x-4 items-center">
                  <img
                    src={`https://${window.location.hostname}/static/assets/user.png`}
                    alt=""
                  />
                  <div className="flex-1">
                    <input
                      type="text"
                      placeholder="Last Name"
                      {...register("last_name", {
                        required: "Last name is required",
                      })}
                      className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                    />
                  </div>
                </div>
                <div className="text-red-700 ml-5 mt-3">
                  {errors.last_name?.message?.toString()}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-[24px] gap-[24px]">
              <div className="p-5 rounded-2xl bg-contactInput">
                <div className="flex space-x-4 items-center">
                  <img
                    src={`https://${window.location.hostname}/static/assets/call.png`}
                    alt=""
                  />
                  <div className="flex-1">
                    <input
                      type="text"
                      {...register("phone_number", {
                        required: "Phone number is required",
                        pattern: {
                          value: new RegExp(/^\d{10}$/g),
                          message: "Must be of 10 digits",
                        },
                      })}
                      placeholder="Mobile Number"
                      className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                    />
                  </div>
                </div>
                <div className="text-red-700 ml-5 mt-3">
                  {errors.phone_number?.message?.toString()}
                </div>
              </div>
              <div className="p-5 rounded-2xl bg-contactInput">
                <div className="flex space-x-4 items-center">
                  <img
                    src={`https://${window.location.hostname}/static/assets/mail.png`}
                    alt=""
                  />
                  <div className="flex-1">
                    <input
                      type="email"
                      placeholder="Email"
                      {...register("email", { required: "Email is required" })}
                      className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                    />
                  </div>
                </div>
                <div className="text-red-700 ml-5 mt-3">
                  {errors.email?.message?.toString()}
                </div>
              </div>
            </div>
            <div className="p-5 rounded-2xl bg-contactInput mt-5">
              <div className="flex space-x-4 items-center">
                <img
                  src={`https://${window.location.hostname}/static/assets/comments.png`}
                  alt=""
                />
                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="Query"
                    className="w-full p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                    {...register("query", { required: "Query is required" })}
                  />
                </div>
              </div>
              <div className="text-red-700 ml-5 mt-3">
                {errors.query?.message?.toString()}
              </div>
            </div>
            <input
              className="bg-primary text-white font-medium w-full lg:w-1/3 py-[15px] mt-[36px] text-center text-lg rounded-[73px] transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
        <div className="flex justify-center order-1 lg:justify-normal lg:order-2">
          <img
            src={`https://${window.location.hostname}/static/assets/Contact.png`}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="htp_landing_blue_two overflow-hidden absolute -left-[1100px] top-0"></div>
      </div>
    </section>
  );
};

export default ContactSection;
