import React, { useContext, useEffect, useState } from "react";
import CartSteps from "./CartSteps";
import { HTPAppContext } from "../../context/HTPContext";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { HTPApi } from "../../api/ApiConfig";
import { paymentHandler } from "../../services/razorpayService";
import { clearCartState } from "../../redux/cart/cartSlice";
import { clearPatientState } from "../../redux/patient/patientSlice";
import Loader from "../loader/Loader";
import { checkUserExistance } from "../../services/clientServices";
import GoBackButton from "../common/GoBackButton";
import useCart from "../../hooks/useCart";

const CartPageMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [hardcopyPrice, setIsHardcopyPrice] = useState(0);
  const [isHardcopySelected, setIsHardcopySelected] = useState(false);
  const [progress, setProgress] = useState(50);

  const { setIsCartStepOpen, handleModal, firebaseUID } =
    useContext(HTPAppContext);

  // make a new state to store tests and test packages
  const cart = useSelector((state) => state.cart);
  const patientDetails = useSelector((state) => state.patient);
  const labSpecs = useSelector((state) => state.labSpecs);

  // const hardcopyPrice
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("idToken");
  const [calculatedPrices, setCalculatedPrices] = useState({
    actual_price: 0,
    discounted_price: 0,
    total_price: 0,
  });
  const params = useParams();
  const {
    removeTestFormCart,
    removeTestPackageFromCart,
    isCartEmpty,
    totalTestsAndPackages,
  } = useCart();

  useEffect(() => {
    const hasPatientData =
      patientDetails.patients.length > 0 &&
      patientDetails.address.id &&
      patientDetails.appointment.length > 0;

    if (hasPatientData) {
      setProgress("50");
    } else {
      setProgress("10");
    }

    // if (cart.tests?.length > 0) {
    //   setIsCartEmpty(false);
    // } else {
    //   setIsCartEmpty(true);
    // }
  }, [cart, patientDetails]);

  useEffect(() => {
    const findUserByFirebaseUid = async () => {
      try {
        const response = await checkUserExistance(firebaseUID);
        if (response.data.exists) {
          setUserData(response.data.user);
        } else {
          throw new Error("User dose not exists");
        }
      } catch (error) {
        console.log(error);
      }
    };

    findUserByFirebaseUid();
  }, [firebaseUID]);

  useEffect(() => {
    const numberOfPatients = patientDetails.patients.length;
    setIsHardcopyPrice(parseInt(labSpecs.general_settings.cost_of_hard_copies));
    // const hardcopyPrice = parseInt(
    //   labSpecs.general_settings.cost_of_hard_copies
    // );

    if (numberOfPatients > 0) {
      setCalculatedPrices({
        actual_price: cart.actual * numberOfPatients,
        discounted_price: cart.discounted * numberOfPatients,
        total_price: isHardcopySelected
          ? cart.discounted * numberOfPatients + hardcopyPrice
          : cart.discounted * numberOfPatients,
      });
    } else {
      setCalculatedPrices({
        actual_price: cart.actual,
        discounted_price: cart.discounted,
        total_price: isHardcopySelected
          ? cart.discounted + hardcopyPrice
          : cart.discounted,
      });
    }
  }, [patientDetails, isHardcopySelected, cart]);

  const handleRemoveFromCart = (testId) => {
    removeTestFormCart(testId);
  };

  const handleRemovePackageFromCart = (packageId) => {
    removeTestPackageFromCart(packageId);
  };

  const handleSchedule = () => {
    if (cart.tests?.length > 0 || cart.testPackages?.length > 0) {
      if (token) {
        (cart?.tests?.length > 0 || cart.testPackages?.length > 0) &&
          setIsCartStepOpen(true);
      } else {
        toast.error("Login first");
        handleModal("login");
      }
    } else {
      toast.error("Cart is empty");
    }
  };

  const clearPersistedState = async () => {
    dispatch(clearCartState());
    dispatch(clearPatientState());
  };

  const handleHardCopyOption = () => {
    if (cart.tests?.length > 0 || cart.testPackages.length > 0) {
      if (isHardcopySelected) {
        setIsHardcopySelected(false);
      } else {
        setIsHardcopySelected(true);
      }
    } else {
      toast.error("Cart is empty");
    }
  };

  const handlePayment = async () => {
    const paymentSettings = {
      razor_key_id: labSpecs.payment_information.razor_key_id,
      razor_key_secret: labSpecs.payment_information.razor_key_secret,
      lab_name: labSpecs.lab_name,
    };
    const totalAmount = calculatedPrices.total_price * 100;
    await paymentHandler(
      paymentSettings,
      totalAmount,
      "INR",
      async (res, rzpTransaction) => {
        const patientList = patientDetails.patients.map((e) => e.id);
        const body = {
          user: userData.id,
          patients: patientList,
          address: patientDetails.address.id,
          tests:
            cart.tests.length > 0 ? cart.tests?.map((e) => e.element.id) : [],
          test_packages:
            cart.testPackages.length > 0
              ? cart.testPackages.map((e) => e.element.id)
              : [],
          order_value: calculatedPrices.total_price,
          order_status: "Completed",
          appointments: patientDetails.appointment,
          hard_copy_report: isHardcopySelected,
        };
        try {
          const response = await HTPApi.post("/api/orders/", body);
          if (response) {
            setIsLoading(true);
            const requestBody = {
              payment_id: rzpTransaction.razorpay_payment_id,
              razorpay_order_id: rzpTransaction.razorpay_order_id,
              signature: rzpTransaction.razorpay_signature,
              payment_status: "Success",
              order_value: calculatedPrices.total_price,
              order_id: response.data.order_id,
              user_id: userData.id,
            };
            try {
              const transaction = await HTPApi.post(
                "/api/transactions/",
                requestBody
              );
              clearPersistedState();
              navigate(`/purchase-complete/${response.data.order_id}`);
              toast.success("Order Placed");
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.log(error);
          toast.error("Session expired");
        } finally {
          setIsLoading(false);
        }
      }
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className="max-w-7xl mx-auto mb-8">
      <div className="ps-2 pb-2">
        <GoBackButton />
      </div>
      <CartSteps />
      <div id="cart-progress" className="flex flex-col mx-3">
        <div className="w-[40%] flex justify-between items-center space-x-5 font-medium">
          <div>My Cart</div>
          <div>Order Summary</div>
          <div>Payment</div>
        </div>
        <div className="bg-white rounded-[20px] h-[10px] w-full md:w-[40%] mt-3">
          <div
            style={{ width: `${progress}%` }}
            className={`bg-primary rounded-[20px] h-[10px]`}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-[16px] mt-8">
        <div className="mx-2 col-span-5">
          <div className="flex justify-between items-center">
            <div className="text-lg font-medium">Order Overview</div>
            <div
              onClick={() => navigate("/categories")}
              className="flex items-center space-x-2 font-semibold text-secondary cursor-pointer"
            >
              <div>Add More Test</div>
              <div>+</div>
            </div>
          </div>

          {patientDetails?.patients?.length > 0 && !isCartEmpty && (
            <>
              <div className="bg-white/30 rounded-[24px] w-full p-4 mt-6 shadow-sm">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <div className="lg:col-span-3 flex justify-between items-center">
                    <div className="text-md font-medium">Patient Details</div>
                    <div
                      onClick={() => setIsCartStepOpen(true)}
                      className="flex items-center space-x-2 font-semibold text-secondary cursor-pointer"
                    >
                      <div>Edit Details</div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="text-lg font-medium">Patient</div>

                    {patientDetails.patients.map((patient, index) => (
                      <div key={index} className="text-sm font-medium">
                        <div>{patient.name}</div>
                        <div>
                          {patient.age}, {patient.gender}
                        </div>
                      </div>
                    ))}
                    <div
                      onClick={() => setIsCartStepOpen(true)}
                      className="space-x-2 font-semibold text-sm text-secondary cursor-pointer"
                    >
                      + Add Patient
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="text-lg font-medium">Address</div>
                    <div className="text-sm">For Home Sample Collection</div>
                    <div className="text-sm font-medium">
                      {`${patientDetails.address.flat}, ${patientDetails.address.landmark}, ${patientDetails.address.city}, ${patientDetails.address.state}, ${patientDetails.address.pin}`}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="text-lg font-medium">Time Slot</div>
                    <div className="text-sm">For Home Sample Collection</div>
                    <div className="text-sm font-medium">
                      <div>{patientDetails.appointment?.slice(0, 10)}</div>
                      <div>{patientDetails.appointment?.slice(11, 16)} AM</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {isCartEmpty && (
            <>
              <div className="bg-white/30 rounded-[24px] w-full py-8 mt-6 shadow-sm">
                <div className="text-center font-medium text-2xl text-slate-700">
                  Cart is empty
                </div>
              </div>
            </>
          )}

          {cart?.testPackages?.map((test, index) => {
            const discountedPrice = Math.trunc(test.element.price);
            const actualPrice = Math.trunc(test.element.mrp_price);
            const discount = Math.trunc(
              ((actualPrice - discountedPrice) / actualPrice) * 100 || 0
            );

            return (
              <div
                key={index}
                className="bg-white/30 rounded-[24px] w-full p-4 mt-6 shadow-sm"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3 ...">
                    <div className="text-xl font-medium mb-1">
                      {test.element.name}
                    </div>
                    {/* {test.element.tests && ( */}
                    <div className="text-sm mb-2">
                      Pathology Tests (
                      <span>
                        {test.element.tests ? test.element.tests?.length : 1}
                      </span>
                      )
                    </div>
                    {/* )} */}
                  </div>
                  <div>
                    {discountedPrice ? (
                      <div className="flex justify-end">
                        <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black ">
                          <div className="flex space-x-3">
                            <div className="font-bold">₹{discountedPrice}</div>
                            <div className="text-[#A6A6A6] line-through">
                              ₹{actualPrice}
                            </div>
                          </div>
                          <div className="text-[#269200] font-bold text-left">
                            {discount}% off
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-end">
                        <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black">
                          <div className="">
                            <div className="font-bold">₹{actualPrice}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div>
                      <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black w-full">
                        <div className="flex space-x-3">
                          <div className="font-bold">₹{discountedPrice}</div>
                          <div className="text-[#A6A6A6] line-through">
                            ₹{actualPrice}
                          </div>
                        </div>
                        <div className="text-[#269200] font-bold text-left">
                          {discount}% off
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {test.element.tests && (
                    <div className="col-span-4">
                      <div className="font-medium text-sm space-y-2">
                        {test.element.tests?.map((test, index) => (
                          <div key={index}>{test.name}</div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="col-span-1">
                    <div
                      className="cursor-pointer flex"
                      onClick={() =>
                        handleRemovePackageFromCart(test.element.id)
                      }
                    >
                      <img
                        src={`https://${window.location.hostname}/static/assets/Delete.png`}
                        alt=""
                        className="mr-1"
                      />
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {cart?.tests?.map((test, index) => {
            const discountedPrice = Math.trunc(test.element.price);
            const actualPrice = Math.trunc(test.element.mrp_price);
            const discount = Math.trunc(
              ((actualPrice - discountedPrice) / actualPrice) * 100 || 0
            );

            return (
              <div
                key={index}
                className="bg-white/30 rounded-[24px] w-full p-4 mt-6 shadow-sm"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3 ...">
                    <div className="text-xl font-medium mb-1">
                      {test.element.name}
                    </div>
                    {/* {test.element.tests && ( */}
                    <div className="text-sm mb-2">
                      Pathology Tests (
                      <span>
                        {test.element.tests ? test.element.tests?.length : 1}
                      </span>
                      )
                    </div>
                    {/* )} */}
                  </div>
                  <div>
                    <div>
                      {/* <div className="flex justify-end">
                        <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black">
                          <div className="">
                            <div className="font-bold">₹{actualPrice}</div>
                          </div>
                        </div>
                      </div> */}
                      {discountedPrice ? (
                        <div className="flex justify-end">
                          <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black ">
                            <div className="flex space-x-3">
                              <div className="font-bold">
                                ₹{discountedPrice}
                              </div>
                              <div className="text-[#A6A6A6] line-through">
                                ₹{actualPrice}
                              </div>
                            </div>
                            <div className="text-[#269200] font-bold text-left">
                              {discount}% off
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-end">
                          <div className="px-[8px] py-[6px] border-dashed border rounded-[10px] border-black">
                            <div className="">
                              <div className="font-bold">₹{actualPrice}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {test.element.tests && (
                    <div className="col-span-4">
                      <div className="font-medium text-sm space-y-2">
                        {test.element.tests?.map((test, index) => (
                          <div key={index}>{test.name}</div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="col-span-1">
                    <div
                      className="cursor-pointer flex"
                      onClick={() => handleRemoveFromCart(test.element.id)}
                    >
                      <img
                        src={`https://${window.location.hostname}/static/assets/Delete.png`}
                        alt=""
                        className="mr-1"
                      />
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mx-5 mt-12 lg:col-span-3">
          <div className="bg-white rounded-[24px] p-[20px]">
            {/* <div className="flex flex-col md:flex-row items-center md:space-x-5">
              <input
                type="text"
                name="coupon"
                placeholder="Enter Coupon Code"
                className="border-b border-black flex-1 p-3 placeholder:text-black outline-none w-full"
              />
              <div className="border border-primary rounded-[20px] px-6 py-2 text-primary hover:bg-primary/80 hover:text-white duration-200 ease-in-out cursor-pointer w-full md:w-fit mt-3 md:mt-0 text-center font-medium">
                Apply
              </div>
            </div> */}
            <div className="bg-[#F2F2F2] p-[12px]  rounded-md space-y-3 font-medium">
              <div className="flex justify-between items-center">
                <div>MRP Total</div>
                <div>₹ {calculatedPrices.actual_price}</div>
              </div>
              <div className="flex justify-between items-center">
                <div>Price Discount</div>
                <div>
                  - ₹{" "}
                  {calculatedPrices.actual_price -
                    calculatedPrices.discounted_price}
                </div>
              </div>

              {isHardcopySelected && (
                <div className="flex justify-between items-center">
                  <div>Hard Copy</div>
                  <div>+ ₹{hardcopyPrice}</div>
                </div>
              )}
              <div className="flex justify-between items-center">
                <div>To Be Paid</div>
                <div className="font-semibold">
                  ₹ {calculatedPrices.total_price}
                </div>
              </div>
            </div>

            {hardcopyPrice != 0 && (
              <>
                <div className="font-medium text-lg mt-5">Value add-ons</div>
                <div className="bg-[#F2F2F2] p-4 mt-2 rounded-md">
                  <div className="flex justify-between">
                    <div className="flex space-x-5">
                      <div>
                        <img
                          src={`https://${window.location.hostname}/static/assets/Print.png`}
                          alt=""
                          className="w-10"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="text-lg">Hard Copy of Reports</div>
                        <div className="text-xs w-full mt-2">
                          Report will be delivered within 3-4 working days.
                        </div>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="hard_copy"
                          id=""
                          className="border border-black h-[28px] w-[28px]"
                          title="Need hard copy?"
                          onChange={() => {
                            handleHardCopyOption();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!patientDetails.hasAllData &&
            (cart.tests?.length > 0 || cart.testPackages?.length > 0) && (
              <button
                className="bg-primary text-white font-medium text-center text-xl rounded-[73px] py-3 px-3 w-full mt-5"
                onClick={() => handleSchedule()}
              >
                Schedule
              </button>
            )}

          {patientDetails.hasAllData &&
            (cart.tests?.length > 0 || cart.testPackages?.length > 0) && (
              <button
                className="bg-primary text-white font-medium text-center text-xl rounded-[73px] py-3 px-3 w-full mt-5"
                onClick={() => handlePayment()}
              >
                Payment
              </button>
            )}
        </div>
      </div>
    </main>
  );
};

export default CartPageMain;
