import { useEffect } from "react";
import { ClipLoader } from "react-spinners";

const Loader = () => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  });
  return (
    <div className="fixed z-50 inset-0 backdrop-blur-xl w-screen h-screen flex justify-center items-center">
      <ClipLoader size={50} color="#014D7B" />
    </div>
  );
};

export default Loader;
