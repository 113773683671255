import { createSlice } from "@reduxjs/toolkit";
//
//
// This slice should not persist in localstorage
//
//
const initialState = {
  id: null,
  name: "",
  description: "",
  is_profile: false,
  tests: [],
  profiles: [],
  actual_price: "0.00",
  discounted_price: "0.00",
  sample_collection_instructions: "",
  slug_field: "",
  item_code: "",
  how_it_helps: "",
  tags: [],
};

export const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    setTestPackage: (state, action) => {
      const { testPackage } = action.payload;
      return {
        ...testPackage,
      };
    },
    clearTestPackage: (state, action) => {
      return initialState;
    },
  },
});

export const { setTestPackage, clearTestPackage } = testsSlice.actions;
export default testsSlice.reducer;
