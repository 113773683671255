import { Dialog } from "@headlessui/react";
import { Footer, Navbar } from "../components";
import AddAddress from "../components/cart/AddAddress";
import CartPageMain from "../components/cart/CartPageMain";
import { useState } from "react";
import AddPatient from "../components/cart/AddPatient";
import SelectPatient from "../components/cart/SelectPatient";

const Cart = () => {

  return (
    <>
      <Navbar />
      <main className="select-none">
        <CartPageMain />
      </main>
      <Footer />
    </>
  );
};

export default Cart;
