import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  findTestPackageById,
  findSingleTestById,
} from "../../services/pakageServices";
import OrdersSkeleton from "../skeleton/OrdersSkeleton";
import { convertToDateTimeString } from "../../utils/timeSlotsUtils";
import { Link } from "react-router-dom";
import {
  findAddressById,
  findPatientById,
} from "../../services/clientServices";
import { HTPAppContext } from "../../context/HTPContext";

const OrderCard = ({ order }) => {
  const [tests, setTests] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(
    convertToDateTimeString(order.appointments)
  );
  const [data, setData] = useState();
  const { token } = useContext(HTPAppContext);

  useEffect(() => {
    const fetchData = async () => {
      const {
        patients,
        address,
        tests,
        test_packages,
        appointments,
        order_reports,
        order_id,
        order_value,
      } = order;

      try {
        const patientsData = await Promise.all(
          patients?.map(async (patientId) => {
            const { data } = await findPatientById(patientId, token);
            return data;
          })
        );

        const addressResponse = await findAddressById(address, token);
        const addressData = addressResponse.data;

        const appointment = convertToDateTimeString(appointments).slice(0, 22);

        setData({
          order_value,
          order_id,
          order_reports,
          patients: patientsData,
          address: addressData,
          appointment,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [order]);

  // useEffect(() => {
  //   const fetchTestsFromPackage = async (id) => {
  //     setLoading(true);
  //     try {
  //       const response = await findTestPackageById(id);
  //       setTests([...response.data.tests]);
  //     } catch (error) {
  //       console.log(error);
  //       toast.error(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   order.test_packages.forEach((testId) => {
  //     fetchTestsFromPackage(testId);
  //   });
  // }, [order]);

  return (
    <section>
      {loading ? (
        <OrdersSkeleton />
      ) : (
        <div className="bg-[#F2F2F2] p-5 rounded-[24px] space-y-2 mb-6">
          <div className="grid md:grid-cols-4 font-medium gap-2">
            <div className="font-bold">Order ID </div>
            <div className=" col-span-3 pb-2 md:pb-0 md:border-none border-b border-slate-500">
              {data?.order_id}
            </div>

            <div className="font-bold">Patients </div>
            <div className=" col-span-3 pb-2 md:pb-0 md:border-none border-b border-slate-500">
              {data?.patients.map((patient, index, array) => {
                return `${patient.name}${
                  index != array.length - 1 ? "," : ""
                } `;
              })}
            </div>
            <div className="font-bold">Appointment </div>
            <div className=" col-span-3">{data?.appointment}</div>

            {data?.order_reports.length > 0 && (
              <>
                <div className="font-bold">Reports </div>
                <div className=" col-span-3 space-y-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                  {data?.order_reports.map((report, index, array) => {
                    return (
                      <div key={index} className="flex justify-between">
                        <div>{report.test_name}</div>
                        <div>
                          <Link
                            to={report.report_file}
                            className="px-4 bg-white border-2 border-primary rounded-full"
                            target={"_blank"}
                          >
                            Report
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {/* {data?.test_packages.length > 0 && (
            <>
              <div className="font-bold">Test Packages</div>
              <div className=" col-span-2 pb-2 md:pb-0 md:border-none border-b border-slate-500">
                {data?.test_packages.map((testPackage, index, array) => {
                  return `${testPackage.name}${
                    index != array.length - 1 ? "," : ""
                  } `;
                })}
              </div>
            </>
          )} */}
          </div>
        </div>

        // <div className="bg-white/20 backdrop-blur-[18px] rounded-[24px]">
        //   <div className="w-full bg-slate-200 rounded-lg px-3 py-6">
        //     <div className="grid grid-cols-3 gap-2">
        //       <div className="col-span-2 font-medium mb-4">
        //         <div>Order ID : {order.order_id}</div>
        //       </div>
        //       <div className="col-span-1 mb-4">
        //         <div className="flex justify-end font-medium">
        //           <div>{appointmentDate.slice(0, 16)}</div>
        //         </div>
        //       </div>

        //       {tests?.map((test, index) => (
        //         <React.Fragment key={index}>
        //           <div className="col-span-1 font-medium">
        //             {order.order_reports[index]?.patient.name}
        //           </div>
        //           <div className="col-span-1 text-left font-medium">
        //             {test.name}
        //           </div>
        //           <div className="col-span-1 text-right font-medium">
        //             <Link
        //               to={order.order_reports[index]?.report_file}
        //               className="px-4 bg-white border-2 border-primary rounded-full"
        //             >
        //               Report
        //             </Link>
        //           </div>
        //         </React.Fragment>
        //       ))}
        //     </div>
        //   </div>
        // </div>
      )}
    </section>
  );
};

export default OrderCard;
