import React from "react";
import { useSelector } from "react-redux";
import useCart from "../../hooks/useCart";

const AtcButton = () => {
  const details = useSelector((state) => state.testPackage);
  const productType = details.tests ? "package" : "single";

  const { isAddedToCart, addProductToCart } = useCart();

  const added = isAddedToCart(details.id, productType);

  return (
    <div>
      <div
        className={`${
          added ? "bg-green-600" : "bg-primary"
        } rounded-[73px] py-[10px] px-[10px] md:px-[18px] text-white font-medium  transition-all duration-200 hover:bg-${
          added ? "green" : "primary/90"
        } hover:scale-105 cursor-pointer`}
        onClick={() => addProductToCart(details, added)}
      >
        <div className="flex space-x-3 justify-center">
          <div>{added ? "Added To Cart" : "Add To Cart"}</div>
          {!added && (
            <img
              src={`https://${window.location.hostname}/static/assets/Add.png`}
              alt=""
              className=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AtcButton;
