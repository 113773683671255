import { Footer, HealthPackages, Navbar } from "../components";

const TestPackages = () => {
  return (
    <>
      <Navbar />
      <main className="select-none">
        <HealthPackages />
        <div className="htp_landing_blue_one overflow-hidden absolute  right-24 -top-[200px] lg:-top-[300px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default TestPackages;
