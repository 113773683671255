import { Dialog } from "@headlessui/react";
import {
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { HTPAppContext } from "../../context/HTPContext";
import {
  getAvailableSlots,
  convertToDateTimeString,
} from "../../utils/timeSlotsUtils";
import { useDispatch, useSelector } from "react-redux";
import { addPatient } from "../../redux/patient/patientSlice";

const SelectSchedule = forwardRef((props, ref) => {
  const { setIsCartStepOpen, setStep, setPatientDetails, patientDetails } =
    useContext(HTPAppContext);
  const [day, setDay] = useState(0);
  const [slots, setSlots] = useState(getAvailableSlots());
  const patientState = useSelector((state) => state.patient);
  const [selectedTimeslot, setSelectedTimeslot] = useState([]);

  const dispatch = useDispatch();

  const handleNextDay = () => {
    if (day < slots.length - 1) {
      setSelectedTimeslot({ selectedTimeslot: null, index: null });
      setDay((prev) => prev + 1);
    }
  };

  const handlePreviousDay = () => {
    if (day != 0) {
      setSelectedTimeslot({ selectedTimeslot: null, index: null });
      setDay((prev) => prev - 1);
    }
  };

  const handleSubmit = () => {
    if (selectedTimeslot.length > 0 || selectedTimeslot.appointment) {
      setStep(1);
      setIsCartStepOpen(false);
      setPatientDetails({
        ...patientDetails,
        appointment: selectedTimeslot.appointment,
      });
      dispatch(
        addPatient({
          patient: {
            ...patientDetails,
            appointment: selectedTimeslot.appointment,
            hasAllData: true,
          },
        })
      );
    }
  };

  //
  // Slot List Component
  //
  const SlotsList = () => {
    const handleTimeslotSelect = (slot, index) => {
      const dateTimeStr = `${slots[day].date} ${slot.start} - ${slot.end}`;
      setSelectedTimeslot({ appointment: slot.dateTimeString, index });
    };

    return (
      <div className="overflow-y-scroll h-[250px]  ps-2">
        {slots[day].timeslots.map((slot, index) => (
          <div
            className={`text-start py-3 px-4 me-2 font-medium cursor-pointer  border-b border-slate-300 ${
              selectedTimeslot.index === index && "bg-blue-200 rounded-md"
            }`}
            key={index}
            onClick={() => handleTimeslotSelect(slot, index)}
          >
            <div className="text-center">
              <div>
                {slot.start} - {slot.end}
              </div>
              {/* <div>+ Rs. {slot.cost} Only</div> */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // * //

  return (
    <div>
      <Dialog.Panel
        ref={ref}
        className="w-[300px] md:w-[417px] overflow-hidden rounded-2xl bg-modelBgCart p-6 shadow-xl"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="me-4" onClick={() => setStep((prev) => prev - 1)}>
              <img
                src={`https://${window.location.hostname}/static/assets/Back.png`}
                alt=""
              />
            </div>
            <div className="text-xl font-medium">Pathology Slot</div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsCartStepOpen(false);
              setStep(1);
            }}
          >
            <img
              src={`https://${window.location.hostname}/static/assets/Close.png`}
              alt=""
            />
          </div>
        </div>
        <div className={`my-2 bg-white h-[10px]`}>
          <div className="w-[100%] bg-primary h-[10px] rounded-lg"></div>
        </div>
        <div className="text-start space-y-2">
          <div className=" font-bold text-xl pt-2">Select Time & Date</div>
          <div className=" font-medium ">
            A phlebotomist will visit at selected time to collected samples
          </div>
          <div className=" rounded-lg h-[300px] ">
            <div className="bg-white rounded-lg  me-2">
              <div className="px-4 py-4 font-medium flex items-start justify-between">
                <div
                  className="cursor-pointer"
                  onClick={() => handlePreviousDay()}
                >
                  <img
                    src={`https://${window.location.hostname}/static/assets/Back.png`}
                    alt=""
                  />
                </div>

                <div>{slots[day].date}</div>

                <div className="cursor-pointer" onClick={() => handleNextDay()}>
                  <img
                    src={`https://${window.location.hostname}/static/assets/Back.png`}
                    className="rotate-180"
                    alt=""
                  />
                </div>
              </div>

              <SlotsList />
            </div>
          </div>
        </div>

        <div
          onClick={() => handleSubmit()}
          className="w-full text-xl text-center font-medium text-white bg-primary rounded-2xl mt-10 p-[16px]"
        >
          Select Slot
        </div>
      </Dialog.Panel>
    </div>
  );
});

export default SelectSchedule;
