import { toTitleCase } from "../../utils/HTPUtils";
import { useParams } from "react-router-dom";
import CheckupCard from "../common/CheckupCard";
import useFetch from "../../hooks/useFetch";
import { dummyTests } from "../../data/HTPData";
import HTPSkeleton from "../skeleton/HTPSkeleton";
import { useEffect, useState } from "react";
import { searchTestPackages } from "../../services/pakageServices";
import toast from "react-hot-toast";
import GoBackButton from "../common/GoBackButton";
import Paginator from "./Paginator";

const HealthPackages = () => {
  const [loading, setLoading] = useState(false);
  const [completeData, setCompleteData] = useState(null);
  const [data, setData] = useState(null);
  const { category } = useParams();
  const categoryName = toTitleCase(category);
  const size = 12;

  useEffect(() => {
    const findTests = async () => {
      setLoading(true);
      try {
        const response = await searchTestPackages(category);
        console.log(response);
        setCompleteData(response.data);
        // setData(response.data);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    findTests();
  }, [category]);

  return (
    <section className="max-w-7xl mx-auto relative">
      <div className="ps-4 pb-2">
        <GoBackButton />
      </div>
      <div className="font-bold text-4xl md:text-5xl mx-5 text-center md:text-left">
        All Health Packages & Tests | {categoryName}
      </div>
      <div className="mt-10 mx-5 mb-8">
        {loading ? (
          <HTPSkeleton size={size} />
        ) : (
          <CheckupCard size={size} data={data} />
        )}
        {data?.length < 1 && !loading && (
          <div className="h-[350px] mt-2 p-4 bg-gray-300 rounded-xl flex justify-center items-center">
            <h1 className="font-medium text-2xl text-primary">
              No Packages/Tests Available
            </h1>
          </div>
        )}
      </div>
      <div className="flex justify-center pb-4">
        <Paginator itemsPerPage={size} items={completeData} setData={setData} />
      </div>
      <div className="htp_landing_blue_two overflow-hidden absolute -left-[1100px] top-0"></div>
    </section>
  );
};

export default HealthPackages;
