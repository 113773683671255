import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useCart from "../../hooks/useCart";

const CheckupCard = ({ data, size = 4 }) => {
  const { isAddedToCart, addProductToCart } = useCart();
  const navigate = useNavigate();

  const handleCardClick = (element, productType) => {
    navigate(`/package/${element.id}?type=${productType}`);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px]">
      {data?.slice(0, size).map((element, index) => {
        const productId = element.id;
        const productType = element?.tests ? "package" : "single";
        const added = isAddedToCart(productId, productType);
        const discountedPrice = Math.trunc(element.price);
        const actualPrice = Math.trunc(element.mrp_price);
        const discount = Math.trunc(
          ((actualPrice - discountedPrice) / actualPrice) * 100 || 0
        );
        const numberOfTests = element?.tests?.length;

        const cardStyles =
          "bg-checkupCard rounded-[24px] p-[20px] flex flex-col space-y-2 ";

        return (
          <div key={element.id} className={cardStyles}>
            <div className="inline-block w-[75px]">
              <div className="bg-[#5ABB6E] flex items-center h-[18px] rounded-[16px] px-[9px] py-3 space-x-2">
                <p className="text-white">Safe</p>
                <img
                  src={`https://${window.location.hostname}/static/assets/Safe.png`}
                  alt="Safe logo"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div
                onClick={() => handleCardClick(element, productType)}
                className="font-bold w-full text-lg leading-tight cursor-pointer"
              >
                {element.name}
              </div>
            </div>
            <div className="mt-[16px] font-medium mb-3">
              Include(s) {numberOfTests ? numberOfTests : "1"} Tests
            </div>

            {element.tests?.slice(0, 4).map((test, index) => (
              <div key={index} className="text-xs">
                {test.name}
              </div>
            ))}
            <Link
              to={`/package/${element.id}?type=${productType}`}
              className="text-secondary font-medium my-5 cursor-pointer"
            >
              More +
            </Link>

            <div className="grow flex flex-col">
              {discountedPrice ? (
                <div className="mt-auto px-[8px] py-[6px] border-dashed border rounded-[10px] border-black w-full md:w-[100%]">
                  <div className="text-[#269200] font-bold text-left">
                    {discount}% OFF
                  </div>
                  <div className="flex space-x-3">
                    <div className="font-bold">₹{discountedPrice}</div>
                    <s className="text-[#A6A6A6]">₹{actualPrice}</s>
                  </div>
                </div>
              ) : (
                <div className="mt-auto px-[8px] py-[6px] border-dashed border rounded-[10px] border-black w-full md:w-[100%]">
                  <div className="">
                    <div className="font-bold">₹{actualPrice}</div>
                  </div>
                </div>
              )}

              <div>
                <div
                  className={`${
                    added ? "bg-green-600" : "bg-primary"
                  } rounded-[73px] py-[10px] px-[10px] md:px-[58px] text-white font-medium mt-[24px] transition-all grow self-end duration-200 hover:bg-${
                    added ? "green" : "primary/90"
                  } hover:scale-105 cursor-pointer`}
                  onClick={() => addProductToCart(element, added)}
                >
                  <div className="flex space-x-3 justify-center">
                    <div>{added ? "Added To Cart" : "Add To Cart"}</div>
                    {!added && (
                      <img
                        src={`https://${window.location.hostname}/static/assets/Add.png`}
                        alt=""
                        className="w-6"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckupCard;
