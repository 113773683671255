export async function getCity(lat, long) {
  // API key is missing
  const res = await fetch(
    `https://geocode.maps.co/reverse?lat=${lat}&lon=${long}&api_key=65b7a642516f8747337916qpj15fc41`
  );
  return await res.json();
}

export const toTitleCase = function (str) {
  const words = str.split(" ");
  const titleCasedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return titleCasedWords.join(" ");
};

export const convertToQueryFormat = (string) => {
  let lowercaseString = string.toLowerCase();
  let finalString = lowercaseString.replace(/\s+/g, "-");
  return finalString;
};
