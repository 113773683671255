import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RouteGuard = ({ Component }) => {
  const isAppReady = useSelector((state) => state.labSpecs.isAppReady);

  return isAppReady ? <Component /> : <Navigate to="/in-progress" />;
};

export default RouteGuard;
