import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  tests: [],
  testPackages: [],
  actual: 0,
  saved: 0,
  discounted: 0,
  isCompleted: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { element } = action.payload;

      if (element.tests) {
        state.testPackages.push({ element, count: 1 });
      } else {
        const existingTestIndex = state.tests.findIndex(
          (test) => test.element.id === element.id
        );

        if (existingTestIndex !== -1) {
          state.tests[existingTestIndex].count += 1;
        } else {
          state.tests.push({ element, count: 1 });
        }
      }

      updateCartTotals(state);
    },

    removeFromCart: (state, action) => {
      const { id } = action.payload;
      const existingTestIndex = state.tests.findIndex(
        (test) => test.element.id === id
      );

      if (existingTestIndex !== -1) {
        const test = state.tests[existingTestIndex];
        if (test.count > 1) {
          test.count -= 1;
        } else {
          state.tests.splice(existingTestIndex, 1);
        }

        updateCartTotals(state);
      }
    },

    removePackageFromCart: (state, action) => {
      const { id } = action.payload;
      const existingTestPackageIndex = state.testPackages.findIndex(
        (test) => test.element.id === id
      );

      if (existingTestPackageIndex !== -1) {
        const test = state.testPackages[existingTestPackageIndex];
        if (test.count > 1) {
          test.count -= 1;
        } else {
          state.testPackages.splice(existingTestPackageIndex, 1);
        }

        updateCartTotals(state);
      }
    },

    clearCartState: (state, payload) => {
      return initialState;
    },
  },
});

const updateCartTotals = (state) => {
  let discountedTests = state.tests.reduce(
    (acc, curr) =>
      (curr.element.price != 0 ? curr.element.price : curr.element.mrp_price) *
        curr.count +
      acc,
    0
  );

  let discountedTestPackages = state.testPackages.reduce(
    (acc, curr) =>
      (curr.element.price != 0 ? curr.element.price : curr.element.mrp_price) *
        curr.count +
      acc,
    0
  );

  let actualTests = state.tests.reduce(
    (acc, curr) => curr.element.mrp_price * curr.count + acc,
    0
  );

  let actualTestPackages = state.testPackages.reduce(
    (acc, curr) => curr.element.mrp_price * curr.count + acc,
    0
  );

  state.discounted = discountedTests + discountedTestPackages;
  state.actual = actualTests + actualTestPackages;

  state.saved = () => {
    if (state.discounted != 0) {
      return ((state.actual - state.discounted) / state.actual) * 100;
    } else {
      return 0;
    }
  };
  console.log(state.actual - state.discounted);
};

export const {
  addToCart,
  removeFromCart,
  removePackageFromCart,
  clearCartState,
} = cartSlice.actions;

const selectCart = (state) => state.cart;
export const selectTests = createSelector(selectCart, (cart) => cart.tests);
export const selectPackages = createSelector(
  selectCart,
  (cart) => cart.testPackages
);
export default cartSlice.reducer;
