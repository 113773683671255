import { useSelector } from "react-redux/es/hooks/useSelector";

const FAQ = () => {
  const packageDetails = useSelector((state) => state.testPackage);

  return (
    <section className=" max-w-7xl mx-auto">
      <div className="font-bold text-xl mb-4">
        Answers to Patient Concerns & Frequently Asked Questions (FAQs)
      </div>
      <div className="font-medium">
        {/* {packageDetails.questions_and_answers
          ?.split("\r\n")
          .map((line, index) => (
            <p key={index} className="mb-4">
              {line}
            </p>
          ))} */}
        <div className="whitespace-pre-wrap">
          {packageDetails.questions_and_answers}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
