import { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import ATC from "../components/packageDetails/ATC";
import Hero from "../components/packageDetails/Hero";
import Overview from "../components/packageDetails/Overview";
import TestsIncluded from "../components/packageDetails/TestsIncluded";
import {
  findTestPackageById,
  findSingleTestById,
} from "../services/pakageServices";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTestPackage, clearTestPackage } from "../redux/tests/testsSlice";
import Loader from "../components/loader/Loader";
import FAQ from "../components/packageDetails/FAQ";

const PackageDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");
  const dispatch = useDispatch();
  const isSingleTest = type === "single";

  useEffect(() => {
    const fetchTestPackageById = async () => {
      try {
        setLoading(true);
        const response = await findTestPackageById(id);
        dispatch(setTestPackage({ testPackage: response.data }));
      } catch (error) {
        setNotFound(true);
        console.log(error.error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchSingleTestById = async () => {
      try {
        setLoading(true);
        const response = await findSingleTestById(id);
        dispatch(setTestPackage({ testPackage: response.data }));
      } catch (error) {
        setNotFound(true);
        console.log(error.error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (type === "package") {
      fetchTestPackageById();
    } else if (type === "single") {
      fetchSingleTestById();
    }

    return () => {
      dispatch(clearTestPackage());
    };
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <main className="select-none">
        {notFound ? (
          <div className="max-w-7xl mx-auto mt-[50px] mb-[100px]">
            <div className="h-[450px] mt-2 p-4 bg-gray-300 rounded-xl flex justify-center items-center">
              <h1 className="font-medium text-2xl text-primary">
                Test/Package not found
              </h1>
            </div>
          </div>
        ) : (
          <>
            <Hero />
            <Overview />
            {!isSingleTest && <TestsIncluded />}
            <FAQ />
            <ATC />
          </>
        )}

        <div className="htp_landing_blue_one overflow-hidden absolute right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default PackageDetails;
