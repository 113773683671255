import { Footer, Navbar } from "../components";
import OrdersList from "../components/orders/OrdersList";

const OrderHistory = () => {
  return (
    <>
      <Navbar />
      <main className="select-none">
        <OrdersList />
        <div className="htp_landing_blue_one overflow-hidden absolute right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default OrderHistory;
