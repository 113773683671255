const HTPSkeleton = ({ size = 4 }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      {new Array(size).fill(0).map((element) => {
        return (
          <div
            role="status"
            key={Math.random()}
            className="h-[350px] bg-gray-300 rounded-xl animate-pulse"
          ></div>
        );
      })}
    </div>
  );
};

export default HTPSkeleton;
