import { useContext, useEffect, useState } from "react";
import AppRoutes from "./routes/AppRoutes";
import ScrollToTop from "./utils/ScrollToTop";
import toast, { Toaster } from "react-hot-toast";
import { findLabData } from "./services/labServices";
import { useDispatch } from "react-redux";
import { setLabSpecs } from "./redux/lab/labSpecs";
import useInterceptor from "./hooks/useInterceptor";
import { HTPAppContext } from "./context/HTPContext";
import Loader from "./components/loader/Loader";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useInterceptor();

  useEffect(() => {
    const getLabSpecs = async () => {
      setLoading(true);
      try {
        const responce = await findLabData();
        dispatch(setLabSpecs(responce.data));
      } catch (error) {
        console.log(error);
        toast.error(`Lab Specs : ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    getLabSpecs();
  }, []);

  return (
    <>
      <Toaster />
      <AppRoutes loading={loading} />
      <ScrollToTop />
    </>
  );
}

export default App;
