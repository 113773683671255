import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SearchCityCombobox } from "./SearchCityCombobox";
import { LocationIcon, SearchIcon } from "../icons/HTPIcons";
import { HTPAppContext } from "../../context/HTPContext";
import CartPopOver from "./CartPopOver";
import AuthModal from "../auth/AuthModal";
import MobileNav from "./MobileNav";
import RegisterModal from "../auth/RegisterModal";
import ProfilePopOver from "./ProfilePopOver";
import { useSelector } from "react-redux";
import WarningBand from "./WarningBand";

const Navbar = () => {
  const { isScrolled, isNavOpen, setIsNavOpen, handleModal, token } =
    useContext(HTPAppContext);

  const labSpecs = useSelector((state) => state.labSpecs);
  const siteSettings = useSelector((state) => state.labSpecs.siteSettings);

  const countryCode = "+91";
  const labPhone = labSpecs.general_settings.call_now_button_number;

  const navbarClasses = `w-full sticky top-0 transition-all z-50 ${
    isScrolled ? "backdrop-blur-md" : ""
  }`;

  return (
    <header className={navbarClasses}>
      <div className="space-y-1  z-50 top-20 right-5">
        {!siteSettings.allowPayments && (
          <WarningBand
            type={"danger"}
            message={"Orders are not accepted currently"}
          />
        )}

        {!siteSettings.allowPayments && (
          <WarningBand
            type={"warning"}
            message={"Social media links are not provided"}
          />
        )}
      </div>

      <AuthModal />
      <RegisterModal />
      <nav className="max-w-7xl mx-auto px-2 py-7">
        <div className="flex items-center justify-between">
          <Link to={"/"}>
            <img
              src={`${labSpecs.lab_logo}`}
              width={"140px"}
              alt="logo"
            />
          </Link>

          <div className="flex flex-col md:flex-row md:space-x-5">
            <div className="relative lg:w-[600px] md:w-full mt-3 md:mt-0">
              <SearchCityCombobox
                placeholder="Search your test here.."
                icon={SearchIcon}
              />
            </div>
          </div>

          <div className="hidden md:flex items-center space-x-6 font-medium">
            <div className="flex gap-2">
              {!token && (
                <>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleModal("login")}
                  >
                    Login
                  </span>
                  <span>|</span>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleModal("register")}
                  >
                    Sign Up
                  </span>
                </>
              )}
            </div>

            {token && <ProfilePopOver />}

            <CartPopOver />

            <Link
              to={"/contact"}
              className="cursor-pointer hover:text-black/90"
            >
              Help ?
            </Link>

            <div>
              <button
                className="bg-primary rounded-3xl font-medium px-8 py-2 text-base text-white mt-5 lg:mt-0 transition-all duration-200 hover:bg-primary/90 hover:scale-105"
                onClick={() =>
                  (window.location.href = `tel:${countryCode}${labPhone}`)
                }
              >
                Call Now
              </button>
            </div>
          </div>
          <div className="md:hidden flex space-x-6 items-center">
            <CartPopOver />
            <img
              src={`https://${window.location.hostname}/static/assets/Menu.png`}
              alt="Menu"
              className="cursor-pointer w-8 h-8"
              onClick={() => {
                setIsNavOpen(!isNavOpen);
              }}
            />
          </div>
        </div>
      </nav>
      <MobileNav />
    </header>
  );
};

export default Navbar;
