import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const labSpecs = useSelector((state) => state.labSpecs);
  const instagramLink = labSpecs.general_settings.instagram_link;
  const facebookLink = labSpecs.general_settings.facebook_link;
  const linkdinLink = labSpecs.general_settings.linkedin_link;

  return (
    <footer className="htp_footer px-[20px] xl:px-[120px] pt-[70px] pb-[50px]  text-center md:text-left items-center  lg:flex-row lg:justify-between">
      <div className="max-w-7xl mx-auto my-8">
        <div className="grid grid-cols-3 gap-4">
          <div className="place-self-start">
            <div className="flex items-center">
              <div className="border-r border-gray-500 pr-2">
                <img
                  width={"140px"}
                  src={`${labSpecs.lab_logo}`}
                  alt="Lab Logo"
                />
              </div>
              <div className="pl-1">
                <img
                  width={"140px"}
                  src={`https://${window.location.hostname}/static/assets/logo-new.svg`}
                  alt="My Labs"
                />
              </div>
              {/* <img src="static/assets/Footer.png" alt="" />
        <img src="static/assets/Footer.png" alt="" /> */}
            </div>
          </div>
          <div className="place-self-center">
            <div className="flex gap-6 font-medium">
              <Link to="/about-us">About Us</Link>
              <Link to="/contact">Contact Us</Link>
              <Link to="/terms-conditions">T & C</Link>
            </div>
          </div>
          <div className="place-self-end">
            <div>
              <div className="text-primary font-medium">Join Us</div>
              <div className="flex space-x-3 mt-4">
                {facebookLink && (
                  <div className="cursor-pointer hover:scale-110 transition-all duration-150">
                    <Link to={facebookLink} target="_blank">
                      <img
                        src={`https://${window.location.hostname}/static/assets/facebook.png`}
                        alt="facebook"
                      />
                    </Link>
                  </div>
                )}

                {instagramLink && (
                  <div className="cursor-pointer hover:scale-110 transition-all duration-150">
                    <Link to={instagramLink} target="_blank">
                      <img
                        src={`https://${window.location.hostname}/static/assets/instagram.png`}
                        alt="instagram"
                      />
                    </Link>
                  </div>
                )}

                {linkdinLink && (
                  <div className="cursor-pointer hover:scale-110 transition-all duration-150">
                    <Link to={linkdinLink} target="_blank">
                      <img
                        src={`https://${window.location.hostname}/static/assets/linkedin.png`}
                        alt="linkdin"
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
