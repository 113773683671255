import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patients: [],
  address: {},
  appointment: [],
  hasAllData: false,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    addPatient: (state, action) => {
      const { patient } = action.payload;
      return {
        ...patient,
      };
    },
    addAddress: (state, action) => {
      const { address } = action.payload;
      state.address = address.address;
      state.state = address.state;
    },
    addAppointment: (state, action) => {
      const { appointment } = action.payload;
      state.appointment = appointment;
    },
    clearPatientState: (state) => initialState,
  },
});

export const { addPatient, addAddress, addAppointment, clearPatientState } =
  patientSlice.actions;
export default patientSlice.reducer;
