import { Disclosure } from "@headlessui/react";
import { useSelector } from "react-redux";

const TestsIncluded = () => {
  const packageDetails = useSelector((state) => state.testPackage);
  return (
    <section className="max-w-7xl mx-auto mb-8">
      <div>
        {/* <div className="font-bold text-xl mb-2">Included Tests</div> */}
        <div className="font-bold text-xl mb-2">
          Package Tests Included in {packageDetails.name}
          <span className="font-normal">
            {" "}
            ({packageDetails.tests?.length} tests)
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            {packageDetails.tests?.map((test) => (
              <Disclosure>
                {({ open }) => (
                  <div className=" border-b-2 border-slate-300 ">
                    <Disclosure.Button className="flex w-full justify-between py-3 text-left font-bold">
                      <span>{test.name}</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="py-2 font-medium">
                      {/* {test.about_test?.split("\r\n").map((line, index) => (
                        <p key={index} className="mb-4">
                          {line}
                        </p>
                      ))} */}
                      <div className="whitespace-pre-wrap">
                        {test.about_test}
                      </div>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestsIncluded;
