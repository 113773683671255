import { Dialog } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HTPAppContext } from "../../context/HTPContext";
import {
  checkUserExistance,
  createAddress,
} from "../../services/clientServices";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const AddPatient = () => {
  const { setStep, firebaseUID, token } = useContext(HTPAppContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const findUserByFirebaseUid = async () => {
      try {
        const response = await checkUserExistance(firebaseUID);
        if (response.data.exists) {
          setUserData(response.data.user);
        } else {
          throw new Error("User dose not exists");
        }
      } catch (error) {
        console.log(error);
      }
    };

    findUserByFirebaseUid();
  }, [firebaseUID]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitForm = async (data) => {
    console.log(data);
    try {
      const response = await createAddress(
        {
          flat: data.flat_number,
          locality: data.locality,
          landmark: data.landmark,
          pin: data.pin,
          city: data.city,
          state: data.state,
          mobile: data.mobileNumber,
          user: userData.id,
        },
        token
      );
      console.log(response);
      toast.success("Address Created");
      setStep(2);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Dialog.Panel className="w-[300px] md:w-[417px] transform overflow-hidden rounded-2xl bg-modelBgCart p-6 text-left align-middle shadow-xl transition-all">
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900"
      >
        <div className="flex items-center space-x-2">
          <div onClick={() => setStep(2)} className="mr-4 cursor-pointer">
            <img
              src={`https://${window.location.hostname}/static/assets/Back.png`}
              alt=""
            />
          </div>
          <div className="text-xl font-medium">Address Details</div>
        </div>
      </Dialog.Title>
      <div className="text-start py-2">
        <div className=" font-bold text-xl pt-2">Add new Address</div>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/Location.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Flat no., Building"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("flat_number", {
                  required: "Flat no., Building is required",
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.flat_number?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/Location.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Locality"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("locality", {
                  required: "Locality is required",
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.locality?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/Landmark.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="Text"
                placeholder="Landmark"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("landmark", {
                  required: "Landmark is required",
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.landmark?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/PinCode.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="Number"
                placeholder="PIN"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("pin", {
                  required: "PIN is required",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Enter a valid Pin Code",
                  },
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.pin?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/State.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="Text"
                placeholder="State"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("state", {
                  required: "State is required",
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.state?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/City.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="Text"
                placeholder="City"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("city", {
                  required: "City is required",
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.city?.message?.toString()}
          </div>
        </div>

        <div className="relative">
          <img
            src={`https://${window.location.hostname}/static/svg/call.png`}
            alt=""
            className="absolute bottom-2 left-1"
          />
          <div className="flex space-x-4 items-center">
            <div className="flex-1">
              <input
                type="Number"
                placeholder="Mobile Number"
                className="w-full ps-10 p-2 outline-none bg-transparent border-b border-black placeholder:text-black"
                {...register("mobileNumber", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Must be 10 digits",
                  },
                })}
              />
            </div>
          </div>
          <div className="text-red-700 ml-5 mt-3">
            {errors.mobileNumber?.message?.toString()}
          </div>
        </div>

        <input
          className="bg-primary text-white font-medium w-full py-[15px] mt-[36px] text-center text-lg rounded-[73px] transition-all duration-200 hover:bg-primary/90 hover:scale-105 cursor-pointer"
          type="submit"
          value="Add"
        />
      </form>
    </Dialog.Panel>
  );
};

export default AddPatient;
