import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ContactUsPage,
  LandingPage,
  PackagesPage,
  CategoriesPage,
  CartPage,
  NotFoundPage,
  PackageDetails,
  PurchaseComplete,
  OrderHistory,
  TermsAndConditions,
  AboutUs,
  InProgress,
} from "../pages";
import RouteGuard from "../guard/RouteGuard";
import Loader from "../components/loader/Loader";

const AppRoutes = ({ loading }) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="/" element={<RouteGuard Component={LandingPage} />} />
      <Route
        path="/contact"
        element={<RouteGuard Component={ContactUsPage} />}
      />
      <Route
        path="/categories"
        element={<RouteGuard Component={CategoriesPage} />}
      />
      <Route path="/cart" element={<RouteGuard Component={CartPage} />} />
      <Route
        path="/packages/:category"
        element={<RouteGuard Component={PackagesPage} />}
      />
      <Route
        path="/purchase-complete/:id"
        element={<RouteGuard Component={PurchaseComplete} />}
      />
      <Route path="/orders" element={<RouteGuard Component={OrderHistory} />} />
      <Route
        path="/package/:id"
        element={<RouteGuard Component={PackageDetails} />}
      />
      <Route
        path="/terms-conditions"
        element={<RouteGuard Component={TermsAndConditions} />}
      />
      <Route path="/about-us" element={<RouteGuard Component={AboutUs} />} />
      <Route path="/in-progress" element={<InProgress />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
