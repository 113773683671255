import { ContactSection, Footer, Navbar } from "../components";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <main className="select-none">
        <ContactSection />
        <div className="htp_landing_blue_one overflow-hidden absolute right-24 -top-[700px] lg:-top-[100px]"></div>
      </main>
      <Footer />
    </>
  );
};

export default ContactUs;
