import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { addToCart, selectTests } from "../../redux/cart/cartSlice";
import toast from "react-hot-toast";
import { dummyTests } from "../../data/HTPData";
import AtcButton from "./AtcButton";
import GoBackButton from "../common/GoBackButton";

const Hero = () => {
  const dispatch = useDispatch();
  const labSpecs=useSelector((state)=>state.labSpecs)
  const packageDetails = useSelector((state) => state.testPackage);
  const parsedDiscountprice = parseInt(packageDetails.price);
  const discountedPrice = Math.trunc(parsedDiscountprice);
  const actualPrice = Math.trunc(packageDetails.mrp_price);
  const discount = Math.trunc(
    ((actualPrice - discountedPrice) / actualPrice) * 100 || 0
  );
  const numberOfTests = packageDetails.tests?.length;

  function handleAddToCart(product, added) {
    if (added) {
      toast.error("Already added to the cart");
      return;
    }
    dispatch(addToCart({ element: product }));
  }

  return (
    <section className="max-w-7xl mx-auto py-4">
      <GoBackButton />
      <div className="grid grid-cols-3 gap-4 items-center">
        <div className="col-span-3 px-2 md:px-0 md:col-span-2">
          <div className="bg-white/20 backdrop-blur-[18px] p-[32px] rounded-[24px] htp_category_card">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-3 ...">
                <div className="flex flex-col">
                  <div className="bg-[#5ABB6E] w-[80px] flex items-center h-[18px] rounded-[16px] px-[9px] py-3 space-x-2 mb-2">
                    <p className="text-white">Safe</p>
                    <img
                      src={`https://${window.location.hostname}/static/assets/Safe.png`}
                      alt=""
                    />
                  </div>
                  <div className="w-full font-medium text-2xl mb-4">
                    {packageDetails.name}
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-6 w-[50%]">
                    <div className="border-l-2 border-primary pl-2">
                      <div>This test is for</div>
                      <div className="font-medium">
                        {packageDetails.who_is_this_test_for || "Not Specified"}
                      </div>
                    </div>
                    <div className="border-l-2 border-primary pl-2">
                      <div>Sample Type</div>
                      <div className="font-medium">
                        {packageDetails.sample_type || "Not Specified"}
                      </div>
                    </div>
                  </div>
                </div>

                {numberOfTests > 1 && (
                  <div className="mb-4">
                    <div className="font-base py-2 text-xl">
                      Include(s) {numberOfTests} Tests
                    </div>
                    <div className="space-y-1">
                      {packageDetails?.tests?.map((test) => (
                        <div className="text-sm font-medium">{test.name}</div>
                      ))}
                    </div>
                  </div>
                )}

                {/* <div className="mt-auto px-[8px] py-[6px] border-dashed border rounded-[10px] border-black inline-block">
                  <div className="text-[#269200] font-bold text-left">
                    {discount}% OFF
                  </div>
                  <div className="flex space-x-3 text-xl">
                    <div className="font-bold">₹{discountedPrice}</div>
                    <s className="text-[#A6A6A6]">₹{actualPrice}</s>
                  </div>
                </div> */}
              </div>

              <div>
                {discountedPrice ? (
                  <div className="mb-2 w-full px-[8px] py-[6px] border-dashed border rounded-[10px] border-black inline-block">
                    <div className="text-[#269200] font-bold text-left">
                      {discount}% OFF
                    </div>
                    <div className="flex space-x-3 text-xl">
                      <div className="font-bold">₹{discountedPrice}</div>
                      <span className="text-[#A6A6A6] line-through">
                        ₹{actualPrice}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="mb-2 w-full px-[8px] py-[6px] border-dashed border rounded-[10px] border-black inline-block">
                    <div className="flex space-x-3 text-xl">
                      <span className="font-bold">₹ {actualPrice}</span>
                    </div>
                  </div>
                )}

                <AtcButton />
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 ">
          <div className="font-bold ml-2">Test conducted by</div>
          <div className="">
            <img
              src={`${labSpecs.lab_logo}`}
              width={"200px"}
              alt="logo"
            />
          </div>
          {/* <div className="text-primary font-bold cursor-pointer">
            Know More..
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
