import { HTPApi } from "../api/ApiConfig";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { HTPAppContext } from "../context/HTPContext";
import useUser from "../hooks/useUser";

const useInterceptor = () => {
  const { setIsAuthDialogOpen } = useContext(HTPAppContext);
  const { removeAccess } = useUser();

  useEffect(() => {
    const interceptor = HTPApi.interceptors.request.use(
      (config) => {
        const token = Cookies.get("idToken");
        const expiryTime = Cookies.get("tokenExpiryTime");
        const currTime = new Date().getTime();

        const isTokenExpired = () => {
          return currTime >= parseInt(expiryTime);
        };

        if (token && expiryTime) {
          if (isTokenExpired()) {
            toast.error("Token expired login again");
            removeAccess();
            setIsAuthDialogOpen(true);
          } else {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );

    return () => {
      HTPApi.interceptors.request.eject(interceptor);
    };
  }, [HTPApi]);

  return [];
};

export default useInterceptor;
