export const dummyPromotion = {
  promotion: [
    {
      title: "Diabetes Package",
      description: "Increased thirst and dry mouth check sugar level now.",
      type: "offer",
      discount: 50,
      url: "/",
    },
    {
      title: "Liver Package",
      description: "Increased thirst and dry mouth check sugar level now.",
      type: "offer",
      discount: 30,
      url: "/",
    },
    {
      title: "Diabetes Screening",
      description: "Increased thirst and dry mouth check sugar level now.",
      type: "offer",
      discount: 20,
      url: "/",
    },
    {
      title: "Fever Package",
      description: "Increased thirst and dry mouth check sugar level now.",
      type: "offer",
      discount: 70,
      url: "/",
    },
  ],
  quickbook: [
    {
      id: 1,
      name: "LFT (Liver Function Test)",
      url: "/",
    },
    {
      id: 2,
      name: "Lipid Profile",
      url: "/",
    },
    {
      id: 3,
      name: "COVID- RTPCR",
      url: "/",
    },
    {
      id: 4,
      name: "Diabetes Screening",
      url: "/",
    },
    {
      id: 5,
      name: "Thyroid Profile Total (T3, T4 & TSH)",
      url: "/",
    },
    {
      id: 6,
      name: "CBC (Complete Blood Count)",
      url: "/",
    },
  ],
};

export const dummyPatients = [
  { id: 1, name: "John Doe", age: 25, gender: "Male" },
  { id: 2, name: "Jane Smith", age: 32, gender: "Female" },
  { id: 3, name: "Michael Johnson", age: 45, gender: "Male" },
  { id: 4, name: "Emily Davis", age: 28, gender: "Female" },
  { id: 5, name: "Daniel Brown", age: 35, gender: "Male" },
  { id: 6, name: "Olivia White", age: 40, gender: "Female" },
  { id: 7, name: "William Miller", age: 22, gender: "Male" },
  { id: 8, name: "Sophia Wilson", age: 38, gender: "Female" },
  { id: 9, name: "Matthew Taylor", age: 29, gender: "Male" },
];

export const dummyAddresses = [
  { address: "123 Main St, Anytown, USA 12345", state: "California" },
  { address: "456 Oak Ave, Suburbia, USA 67890", state: "New York" },
  { address: "789 Pine Ln, Smallville, USA 23456", state: "Texas" },
  { address: "101 Elm Blvd, Countryside, USA 78901", state: "Florida" },
  { address: "202 Maple Dr, Metropolis, USA 34567", state: "Illinois" },
];

export const dummyTests = [
  {
    id: 1,
    title: "Comprehensive Gold Full Body Checkup with Smart Report",
    noOfTests: 79,
    tags: ["Serum Calcium", "CRP (C-Reactive Protein) - Quantitative"],
    actualPrice: 4000,
    discountedPrice: 2000,
    discount: 50,
    how_it_helps:
      "A Comprehensive Gold Full Body Checkup with Smart Report comprises various tests that help determine your overall health status. It helps screen for early signs or risk factors of various health concerns or the presence of deficiencies or diseases in the body.",
    precautions:
      "Overnight fasting (8-12 hrs) is required. Do not eat or drink anything except water before the test.",
    tests: [
      "Serum Calcium",
      "CRP (C-Reactive Protein) - Quantitative",
      "LFT (Liver Function Test) (includes 11 tests)",
      "Serum Iron Studies Basic (includes 4 tests)",
      "HBsAg Screening - Rapid",
      "Thyroid Profile Total (T3, T4 & TSH)(includes 3 tests)",
      "Vitamin Profile (includes 3 tests)",
      "Lipid Profile (includes 8 tests)",
      "Rheumatoid Factor - Quantitative",
      "KFT with Electrolytes (Kidney Function Test with Electrolytes) (includes 6 tests)",
      "ESR (Erythrocyte Sedimentation Rate)",
      "Peripheral Smear Examination",
      "Diabetes Screening (HbA1C & Fasting Sugar)(includes 2 tests)",
      "Urine R/M (Urine Routine & Microscopy)(includes 12 tests)",
      "Microalbumin Creatinine Ratio, Urine (includes 3 tests)",
      "CBC (Complete Blood Count) (includes 21 tests)",
    ],
  },
  {
    id: 2,
    title: "Good Health Silver Package with Smart Report",
    noOfTests: 3,
    tags: ["HbA1c", "Cholesterol Total"],
    actualPrice: 4000,
    discountedPrice: 2000,
    discount: 50,
    how_it_helps:
      "The Good Health Silver Package with Smart Report is designed to assess key indicators of your cardiovascular health and diabetes risk. It provides valuable insights into your blood sugar levels and cholesterol levels.",
    precautions:
      "It is recommended to fast for at least 8 hours before taking this test. Drink plenty of water before the test to stay hydrated.",
    tests: ["HbA1c", "Cholesterol Total", "Blood Pressure"],
  },
  {
    id: 3,
    title: "Good Health Smart Package",
    noOfTests: 3,
    tags: ["Serum Creatinine", "Blood Urea"],
    actualPrice: 4000,
    discountedPrice: 2000,
    discount: 50,
    how_it_helps:
      "The Good Health Smart Package is a focused set of tests that evaluate your kidney function. It helps identify any abnormalities in serum creatinine and blood urea levels.",
    precautions:
      "Avoid excessive physical activity before the test. Ensure you are well-hydrated for accurate results.",
    tests: ["Serum Creatinine", "Blood Urea", "Electrolyte Panel"],
  },
  {
    id: 4,
    title: "Fever Advanced Panel",
    noOfTests: 3,
    tags: ["Chikungunya IgM", "Dengue Fever NS1 Antigen"],
    actualPrice: 4000,
    discountedPrice: 2000,
    discount: 50,
    how_it_helps:
      "The Fever Advanced Panel is specifically designed to diagnose fevers caused by infections such as Chikungunya and Dengue. It helps in early detection for prompt medical intervention.",
    precautions:
      "Inform the healthcare provider about any recent fever symptoms or travel to areas with a high incidence of mosquito-borne diseases.",
    tests: [
      "Chikungunya IgM",
      "Dengue Fever NS1 Antigen",
      "Complete Blood Count (CBC)",
    ],
  },
];

export const categories = [
  "Fever",
  "Women Health",
  "Diabetes",
  "Fitness",
  "Covid 19",
  "Senior Citizen",
  "Allergy Profiles",
  "Lifestyle Habits",
  "Full Body Check Up",
  "Pregnancy",
  "Men Health",
  "Good Health",
  "Liver Profiles",
  "Comprehensive Health",
  "Kidney Profiles",
  "Vitamin Tests",
  "Polycystic Ovarian Disease",
  "Iron Studies",
  "Arthritis",
  "Cancer",
];

export const packageChips = [
  "Popular Packages",
  "Fever",
  "Women Health",
  "Diabetes",
  "Fitness",
  "All",
];

export const testChips = [
  "Popular Tests",
  "Fever",
  "Women Health",
  "Diabetes",
  "Fitness",
  "All",
];

export const sampleOrders = [
  {
    order_id: "7b6e59c4-87b7-43d4-aa10-2dfcbbd7e7b2",
    user: {
      id: 2,
      name: "Rahul Sharma",
      address: "Delhi",
      gender: "Male",
      phone: "9876543210",
      pincode: "110001",
      firebase_uid: null,
      created_at: "2024-02-17T09:30:20.147159Z",
      updated_at: "2024-02-17T09:30:20.152096Z",
      lab_name: "Dr Gupta",
    },
    tests: [3, 8],
    test_packages: [1],
    order_reports: [
      {
        patient: {
          id: 3,
          user: 2,
          name: "Suresh",
          age: 45,
          gender: "Male",
        },
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:48Z",
        updated_at: "2024-02-17T11:51:19.705784Z",
      },
      {
        patient: {
          id: 4,
          user: 2,
          name: "Meena",
          age: 38,
          gender: "Female",
        },
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:55Z",
        updated_at: "2024-02-17T11:51:29.146285Z",
      },
    ],
    order_value: "75000.00",
    order_status: "Pending",
    appointments: "2024-02-17T08:30:00Z",
    hard_copy_report: false,
    created_at: "2024-02-17T08:30:02.483942Z",
    updated_at: "2024-02-17T11:51:17.441862Z",
    lab_name: "Dr Gupta",
  },
  {
    order_id: "9fae4b3e-29b9-4c1d-9b23-fd38b4edff45",
    user: {
      id: 3,
      name: "Anita Singh",
      address: "Mumbai",
      gender: "Female",
      phone: "8765432109",
      pincode: "400001",
      firebase_uid: null,
      created_at: "2024-02-18T10:20:40.147159Z",
      updated_at: "2024-02-18T10:20:40.152096Z",
      lab_name: "Dr Reddy",
    },
    tests: [6, 12],
    test_packages: [1],
    order_reports: [
      {
        patient: {
          id: 5,
          user: 3,
          name: "Ramesh",
          age: 32,
          gender: "Male",
        },
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-18T12:30:48Z",
        updated_at: "2024-02-18T12:31:19.705784Z",
      },
      {
        patient: {
          id: 6,
          user: 3,
          name: "Sita",
          age: 28,
          gender: "Female",
        },
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrReddy_9fae4b3e-29b9-4c1d-9b23-fd38b4edff45_20240218123055_warranty.pdf",
        created_at: "2024-02-18T12:30:55Z",
        updated_at: "2024-02-18T12:31:29.146285Z",
      },
    ],
    order_value: "60000.00",
    order_status: "Pending",
    appointments: "2024-02-18T09:00:00Z",
    hard_copy_report: false,
    created_at: "2024-02-18T09:00:02.483942Z",
    updated_at: "2024-02-18T12:31:17.441862Z",
    lab_name: "Dr Reddy",
  },
  {
    order_id: "4c0650f2-16ae-4ba4-9fa5-32c667e5b2a1",
    user: {
      id: 4,
      name: "Sanjay Patel",
      address: "Ahmedabad",
      gender: "Male",
      phone: "9876543210",
      pincode: "380001",
      firebase_uid: null,
      created_at: "2024-02-19T08:45:30.147159Z",
      updated_at: "2024-02-19T08:45:30.152096Z",
      lab_name: "Dr Shah",
    },
    tests: [1, 9],
    test_packages: [1],
    order_reports: [
      {
        patient: {
          id: 7,
          user: 4,
          name: "Raj",
          age: 50,
          gender: "Male",
        },
        test_name: "Dummy Test 01",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrShah_4c0650f2-16ae-4ba4-9fa5-32c667e5b2a1_20240219123048_warranty.pdf",
        created_at: "2024-02-19T12:30:48Z",
        // remaining properties truncated for brevity
      },
    ],
  },
];

export const newOrderList = [
  {
    order_id: "7b6e59c4-87b7-43d4-aa10-2dfcbbd7e7b3",
    user: 2,
    patients: [1, 2],
    tests: [3, 8],
    address: 1,
    test_packages: [],
    order_reports: [
      {
        patient: {
          id: 5,
          user: 2,
          name: "John",
          age: 55,
          gender: "Male",
        },
        test_name: "Dummy Test 01",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:48Z",
        updated_at: "2024-02-17T11:51:19.705784Z",
      },
      {
        patient: {
          id: 6,
          user: 2,
          name: "Jane",
          age: 30,
          gender: "Female",
        },
        test_name: "Dummy Test 01",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:55Z",
        updated_at: "2024-02-17T11:51:29.146285Z",
      },
    ],
    order_value: "75000.00",
    order_status: "Pending",
    appointments: "2024-02-17T08:30:00Z",
    hard_copy_report: false,
    created_at: "2024-02-17T08:30:02.483942Z",
    updated_at: "2024-02-17T11:51:17.441862Z",
    lab_name: "Dr Gupta",
  },
  {
    order_id: "c95f37f0-3d9b-4b92-96d2-9bc845cb37e9",
    user: 3,
    patients: [2, 3],
    address: 1,
    tests: [5, 9],
    test_packages: [],
    order_reports: [
      {
        patient: {
          id: 7,
          user: 3,
          name: "David",
          age: 42,
          gender: "Male",
        },
        test_name: "Dummy Test 02",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:48Z",
        updated_at: "2024-02-17T11:51:19.705784Z",
      },
      {
        patient: {
          id: 8,
          user: 3,
          name: "Emma",
          age: 35,
          gender: "Female",
        },
        test_name: "Dummy Test 02",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:55Z",
        updated_at: "2024-02-17T11:51:29.146285Z",
      },
    ],
    order_value: "82000.00",
    order_status: "Completed",
    appointments: "2024-02-18T09:00:00Z",
    hard_copy_report: true,
    created_at: "2024-02-17T09:00:00.483942Z",
    updated_at: "2024-02-18T11:51:17.441862Z",
    lab_name: "Dr Smith",
  },
  {
    order_id: "3b2075f5-7d20-4296-b1c5-32a5141ee713",
    user: 4,
    patients: [3, 4],
    address: 1,
    tests: [7, 10],
    test_packages: [],
    order_reports: [
      {
        patient: {
          id: 9,
          user: 4,
          name: "Michael",
          age: 50,
          gender: "Male",
        },
        test_name: "Dummy Test 03",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:48Z",
        updated_at: "2024-02-17T11:51:19.705784Z",
      },
      {
        patient: {
          id: 10,
          user: 4,
          name: "Olivia",
          age: 28,
          gender: "Female",
        },
        test_name: "Dummy Test 03",
        report_file:
          "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
        created_at: "2024-02-17T11:50:55Z",
        updated_at: "2024-02-17T11:51:29.146285Z",
      },
    ],
    order_value: "68000.00",
    order_status: "Pending",
    appointments: "2024-02-20T10:30:00Z",
    hard_copy_report: false,
    created_at: "2024-02-17T10:30:02.483942Z",
    updated_at: "2024-02-20T11:51:17.441862Z",
    lab_name: "Dr Johnson",
  },
];

export const completedOrder = {
  order_id: "7b6e59c4-87b7-43d4-aa10-2dfcbbd7e7b2",
  user: {
    id: 2,
    name: "Rahul Sharma",
    address: "Delhi",
    gender: "Male",
    phone: "9876543210",
    pincode: "110001",
    firebase_uid: null,
    created_at: "2024-02-17T09:30:20.147159Z",
    updated_at: "2024-02-17T09:30:20.152096Z",
    lab_name: "Dr Gupta",
  },
  tests: [3, 8],
  test_packages: [],
  order_reports: [
    {
      patient: {
        id: 3,
        user: 2,
        name: "Suresh",
        age: 45,
        gender: "Male",
      },
      test_name: "Dummy Test 01",
      report_file:
        "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
      created_at: "2024-02-17T11:50:48Z",
      updated_at: "2024-02-17T11:51:19.705784Z",
    },
    {
      patient: {
        id: 4,
        user: 2,
        name: "Meena",
        age: 38,
        gender: "Female",
      },
      test_name: "Dummy Test 01",
      report_file:
        "https://htp-lab-assets.s3.amazonaws.com/order_reports/DrRawal_43ac7d21-bd61-4e78-be99-92d7aec7abae_20240216125117_warranty.pdf",
      created_at: "2024-02-17T11:50:55Z",
      updated_at: "2024-02-17T11:51:29.146285Z",
    },
  ],
  order_value: "75000.00",
  order_status: "Pending",
  appointments: "2024-02-17T08:30:00Z",
  hard_copy_report: false,
  created_at: "2024-02-17T08:30:02.483942Z",
  updated_at: "2024-02-17T11:51:17.441862Z",
  lab_name: "Dr Gupta",
};
