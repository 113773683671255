const STARTING_HOUR = 6;
const ENDING_HOUR = 20;

function getFormattedDate(date) {
  const newdate = date.split(" ").slice(0, -1);
  return newdate.join(" ");
}

// "2024-01-22T17:37:59.857000Z"
function getTimeSlots(counter) {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + counter);
  let formatedDate = currentDate.toISOString().slice(0, 11);

  let slots = [];
  for (let i = STARTING_HOUR; i < ENDING_HOUR - 1; i++) {
    let minutes = 0;
    for (let j = 1; j <= 2; j++) {
      slots.push({
        dateTimeString:
          formatedDate +
          `${i.toString().padStart(2, "0")}:${j
            .toString()
            .padStart(2, "0")}:00.000Z`,
        start: `${i}:${!minutes ? "00" : minutes} ${i >= 12 ? "PM" : "AM"}`,
        end: `${!(minutes + 30 === 60) ? i : i + 1}:${
          !(minutes + 30 === 60) ? minutes + 30 : "00"
        } ${i + 1 >= 12 ? "PM" : "AM"}`,
        cost: 75,
      });
      minutes = minutes + 30;
    }
  }
  return slots;
}

export function getAvailableSlots() {
  let slots = [];
  let currentDate = new Date();
  const istOffset = 330 * 60 * 1000;
  currentDate.setTime(currentDate.getTime() + istOffset);
  let labels = ["Today", "Tomorrow", "In 2 days", "In 3 days"];
  for (let i = 0; i < 4; i++) {
    slots.push({
      date: getFormattedDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + i
        ).toDateString()
      ),
      label: labels[i],
      timeslots: [...getTimeSlots(i)],
    });
  }
  return slots;
}

export const convertToDateTimeString = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const istOffset = 330 * 60 * 1000;
  // date.setTime(date.getTime() + istOffset);
  return date.toUTCString();
};

export const addSlotTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const offset = 1800 * 1000;
  date.setTime(date.getTime() + offset);
  return date.toUTCString();
};
